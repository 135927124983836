import { Divider, Grid, Typography } from "@mui/material";

type P = {
  contentIcon: JSX.Element;
  contentTitle: string;
  contents: Array<string>;
  contentChildren?: JSX.Element;
};

export const ScheduleContentList: React.FC<P> = ({
  contentIcon,
  contentTitle,
  contents,
  contentChildren
}) => {
  return (
    <Grid item container xs={12} data-testid={`${contentTitle}-content-list-grid`}>
      {/* content list header */}
      <Grid
        item
        xs={12}
        display={"flex"}
        alignItems={"center"}
        data-testid={`${contentTitle}-content-list-grid-header-item`}
      >
        {contentIcon}
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", ml: 1.5 }}>
          {contentTitle}
        </Typography>
      </Grid>

      {/* content list items */}
      {contents.length > 0 && (
        <Grid
          item
          xs={12}
          sx={{ pl: 4, mt: 2 }}
          data-testid={`${contentTitle}-content-list-grid-list-item`}
        >
          {contents.map(content => (
            <Typography key={content}>{content}</Typography>
          ))}
        </Grid>
      )}

      {contentChildren && (
        <Grid
          item
          xs={12}
          sx={{ pl: 4, mt: 2 }}
          data-testid={`${contentTitle}-content-list-grid-item-children`}
        >
          {contentChildren}
        </Grid>
      )}

      <Divider
        orientation={"horizontal"}
        color={"#aaa"}
        variant={"fullWidth"}
        sx={{ width: "100%", mt: 2 }}
      />
    </Grid>
  );
};
