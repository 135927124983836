import { forwardRef, useState } from "react";
import { Grid, TextField, Typography } from "@mui/material";
import { FilterList } from "@mui/icons-material";
import { t } from "i18next";
import { ConnectedBookingData } from "../../../features/Booking-Form/typings/connected-bookingData";

type P = {
  connectedBookingData: ConnectedBookingData[];
};

export const ColleagueBookingDataContents: React.FC<P> = forwardRef<HTMLDivElement, P>(
  (props, ref) => {
    const { connectedBookingData } = props;
    const [filterName, setFilterName] = useState<string>("");

    return (
      <div ref={ref}>
        <Grid container display={"block"}>
          {/* sub-header of colleagues booking data dialog */}
          <Grid item sx={{ pt: 1, pb: 1 }}>
            <Typography>
              {connectedBookingData.length
                ? t("_colleagueBookingDataSubtitleExisted", {
                    length: connectedBookingData.length
                  })
                : t("_colleagueBookingDataSubtitleNotExisted")}
            </Typography>
          </Grid>

          {/* filter of booking data */}
          <Grid item sx={{ mt: 2, mb: 5 }}>
            <TextField
              data-testid="booking-data-filter-field"
              fullWidth
              placeholder={t("Filter")}
              InputProps={{ startAdornment: <FilterList sx={{ mr: 1 }} /> }}
              onChange={event => setFilterName(event.target.value.toLowerCase())}
            />
          </Grid>

          {/* list of booking data */}
          <Grid item container sx={{ mt: 2 }}>
            {connectedBookingData
              .filter(({ firstName, lastName }) =>
                firstName.concat(` ${lastName}`).includes(filterName)
              )
              .map(data => {
                return (
                  <Grid item key={data.userId}>
                    <Typography>
                      {data.firstName} {data.lastName}
                    </Typography>
                  </Grid>
                );
              })}
          </Grid>
        </Grid>
      </div>
    );
  }
);
