import { MutableRefObject, useEffect, useState } from "react";
import { Alert, Box, Button, useTheme } from "@mui/material";
import { Add, Refresh } from "@mui/icons-material";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "../../../../app/helpers";
import { RootState } from "../../../../app/rootReducer";
import DefaultSpinner from "../../../../components/LoadingSpinner/default-spinner.component";
import { Timeframe } from "../../../../features/FloorManager/typings/shared/timeframe";
import { finishInit } from "../../../../functions/FacilityManagerInitializer";
import { useRemoteFetchFloorPlan } from "../../../../hooks/Remote/FloorPlan/useRemoteFetchFloorPlan";
import currentPlanUniqueTimeframesSelector from "../../selectors/currentPlanUniqueTimeframesSelector";
import selectedEntitiesSelector from "../../selectors/selectedEntitiesSelector";
import InitializingLoadingScreenModal from "../initializing-loadingscreen.component";
import TimelinesTimeframe from "../timeline-timeframes.partial";
import { NewTimeframeCreator } from "./NewTimeframeCreator";
import { useRemoteFetchFloorPlanService } from "../../../../hooks/Remote/FloorPlan/useRemoteFetchFloorPlanService";
import { IFloorPayload } from "../../../../components/FacilityManager/Domain/Types/FloorPlan/FloorPayload.type";

type Props = {
  floorTimeframes: Timeframe[];
  refetchFloorTimeframes: () => void;
};

const TimeframesMenu: React.FC<Props> = ({ floorTimeframes, refetchFloorTimeframes }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const dispatch = useDispatch();
  const selected = useSelector(selectedEntitiesSelector);
  const uniqueTimeframes = useSelector(currentPlanUniqueTimeframesSelector);
  const { isInitializing: isInitializingPlan } = useSelector(
    (state: RootState) => state.floorManager.floors
  );

  const [newTimeframe, setNewTimeframe] = useState(false);
  const [startDate, setStartDate] = useState<DateTime | null>(null);

  const [selectedTimeframe, setSelectedTimeframe] = useState<Timeframe>({ start: "", end: "" });
  const [newTimeframeData, setNewTimeframeData] = useState<Timeframe>({ start: "", end: "" });

  const [createFirstTimeframe, setCreateFirstTimeframe] = useState(false);
  const [editTimeframe, setEditTimeframe] = useState(false);
  const [continueTimeframe, setContinueTimeframe] = useState(false);

  const [isEditService, setIsEditService] = useState(false);

  const { data: floorPlan, isFetching: floorPlanIsFetching } = useRemoteFetchFloorPlan(
    selected.floor?.id || 0,
    selectedTimeframe.start,
    selectedTimeframe.end
  );
  const { data: floorPlanForService, isFetching: floorPlanForServiceIsFetching } =
    useRemoteFetchFloorPlanService(
      selected.floor?.id || 0,
      selectedTimeframe.start,
      null,
      isEditService
    );

  const clickEdit = async (timeframe: Timeframe) => {
    setSelectedTimeframe(timeframe);
    setEditTimeframe(true);
  };

  const editServices = (timeframe: Timeframe) => {
    setSelectedTimeframe(timeframe);
    setIsEditService(true);
  };

  useEffect(() => {
    // edit the floor plan only for services
    if (isEditService && !floorPlanForServiceIsFetching && floorPlanForService && floorPlan) {
      /** before BE implements, if normal floor plan has zone equipments attach those data for floorPlanForService */
      const zoneInclEquip = floorPlanForService.zones.map(zone => {
        const found = floorPlan.zones.find(zn => zone.id === zn.id);
        return {
          ...zone,
          equipments: found?.equipments,
          equipmentInventoryIds: found?.equipmentInventoryIds,
          zoneEquipments: found?.zoneEquipments
        };
      });

      finishInit(dispatch, selectedTimeframe, {
        ...({ ...floorPlanForService, zones: [...zoneInclEquip] } as IFloorPayload),
        freeInventories: { ...floorPlan.freeInventories }
      });
      setIsEditService(false);
    }
  }, [isEditService, floorPlanForServiceIsFetching, floorPlanIsFetching]);

  useEffect(() => {
    // show plan or edit the last time frame
    if (editTimeframe && !floorPlanIsFetching && floorPlan) {
      finishInit(dispatch, selectedTimeframe, floorPlan);
      setEditTimeframe(false);
    }
  }, [editTimeframe, floorPlanIsFetching]);

  useEffect(() => {
    // timeframe is existed but create new one
    if (continueTimeframe && !floorPlanIsFetching && floorPlan) {
      finishInit(dispatch, newTimeframeData, floorPlan);
      setContinueTimeframe(false);
    }
  }, [continueTimeframe, newTimeframeData, floorPlanIsFetching]);

  useEffect(() => {
    // initial the very first timeframe
    if (createFirstTimeframe && floorPlan) {
      finishInit(dispatch, newTimeframeData, floorPlan);
      setCreateFirstTimeframe(false);
    }
  }, [createFirstTimeframe, newTimeframeData, floorPlan]);

  const clickNextCreate = (startDay: string) => {
    if (floorTimeframes.length) {
      setSelectedTimeframe(floorTimeframes.slice(-1)[0]);
      setNewTimeframeData({ start: startDay, end: null });
      setContinueTimeframe(true);
    } else {
      const firstFloorPlanTimeframe: Timeframe = { start: startDay, end: null };
      setSelectedTimeframe(firstFloorPlanTimeframe);
      setNewTimeframeData(firstFloorPlanTimeframe);
      setCreateFirstTimeframe(true);
    }
  };

  if (!uniqueTimeframes || !selected.floor) return <DefaultSpinner />;
  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <Alert severity="info">{t(`_editOrNewTimeframe`)}</Alert>
      <Button
        data-testid="refresh-btn"
        variant={"text"}
        sx={{
          margin: "40px 10px 0 10px !important",
          textTransform: "none",
          fontSize: "1.1rem",
          color: theme.palette.text.primary
        }}
        onClick={() => refetchFloorTimeframes()}
      >
        <Refresh /> {t("Refresh")}
      </Button>
      <TimelinesTimeframe
        timeframes={floorTimeframes}
        clickEdit={clickEdit}
        selectedFloor={selected.floor.id}
        refetchFloorTimeframes={refetchFloorTimeframes}
        editServices={editServices}
      />
      {!newTimeframe && (
        <Button
          data-testid="new-btn"
          sx={{
            margin: "40px 10px 0 10px !important",
            textTransform: "none",
            fontSize: "1.1rem"
          }}
          onClick={() => setNewTimeframe(true)}
          variant="contained"
          startIcon={<Add />}
        >
          {t("Create new timeframe")}
        </Button>
      )}
      {newTimeframe && (
        <NewTimeframeCreator
          startDate={startDate}
          setStartDate={setStartDate}
          clickNextCreate={clickNextCreate}
          floorTimeframes={floorTimeframes}
        />
      )}
      <InitializingLoadingScreenModal
        isOpen={isInitializingPlan}
        title={t("Calculating basis for your next plan...")}
      />
    </Box>
  );
};

export default TimeframesMenu;

export function checkPreviousAndSelectedTimeframe(
  previousSelectedTimeframe: MutableRefObject<Timeframe>,
  selectedTimeframe: Timeframe,
  newTimeframe: boolean,
  selectTimeframe: () => void
) {
  if (
    previousSelectedTimeframe.current.start == selectedTimeframe.start ||
    previousSelectedTimeframe.current.end == selectedTimeframe.end ||
    newTimeframe
  ) {
    selectTimeframe();
  } else {
    previousSelectedTimeframe.current = selectedTimeframe;
  }
}

export function checkStartAndReturnTargetFrame(start: DateTime | null, end: DateTime | null) {
  if (!start) throw new Error("Timeframe needs start");

  const targetFrame = {
    start: start.toISO(),
    end: end?.toISO() ?? null
  };

  return targetFrame;
}
