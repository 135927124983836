import { ReactNode } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Skeleton,
  Typography
} from "@mui/material";
import { t } from "i18next";
import { FileObject } from "react-mui-dropzone";
import { EquipmentRow } from "./typings/equipment-row.types";
import { MRT_EditActionButtons, MRT_Row, MRT_TableInstance } from "material-react-table";
import ShowAssignedFloor from "../show-assigned-floor.component";
import { PictureDropzone } from "../../Pickers/picture-dropzone.component";

/** display Equipment Image */
export const renderEquipmentImage = (params: EquipmentRow, height: number) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
      {params.imageUrl && (
        <img
          alt="equipment-image"
          height={height}
          src={params.imageUrl} // ?? `https://picsum.photos/${height}/${height}`}
          loading="lazy"
          style={{ borderRadius: "8px" }}
        />
      )}
      {!params.imageUrl && (
        <Skeleton variant={"rounded"} height={height} width={height} animation={false}>
          <Box width={height} height={height} sx={{ textAlign: "center", alignContent: "center" }}>
            <Typography variant={"body1"} sx={{ visibility: "visible" }}>
              {t("No Image")}
            </Typography>
          </Box>
        </Skeleton>
      )}
    </Box>
  );
};

/** Button to display a workplace in the Facility Manager */
export const renderAssignedButton = (
  row: MRT_Row<EquipmentRow>,
  setSelectedInventoryId: (inventoryId: number) => void,
  setFloorPlan: (floorPlan: any) => void,
  setOpen: (open: boolean) => void
) => {
  // retrieve device data for a given device schedule
  return (
    <Box>
      {/* check if status is placed or booked, then it needs to display the relevant status and 'show details' */}
      {/* <Typography>{t(`${row.original.status}`)}</Typography> */}
      <ShowAssignedFloor
        params={row.original}
        setSelectedInventoryId={setSelectedInventoryId}
        setFloorPlan={setFloorPlan}
        setOpen={setOpen}
        noPlaceButtonText={t("Not placed")}
        showButtonText={!!row.original.placeInventoryId ? t("Show Workplace") : t("Show Zone")}
      />
    </Box>
  );
};

export const EditEquipmentImage = ({
  header,
  rowOriginal,
  imageFile,
  setImageFile
}: {
  header: string;
  rowOriginal: EquipmentRow;
  imageFile: { file: FileObject[] };
  setImageFile: (newImageFile: { file: FileObject[] }) => void;
}) => {
  return (
    <Box>
      <Typography sx={{ mb: 2 }}>{header}</Typography>
      {renderEquipmentImage(rowOriginal, 200)}
      <Divider sx={{ mt: 2, mb: 2, width: 200 }} />
      <PictureDropzone
        files={imageFile.file}
        error={!imageFile.file}
        current={rowOriginal.imageUrl ?? ""}
        onAdd={files => setImageFile({ file: files })}
        onDelete={() => setImageFile({ file: [] })}
      />
    </Box>
  );
};

export function SlidePanelCreateEdit({
  table,
  row,
  internalEditComponents,
  headline
}: {
  table: MRT_TableInstance<EquipmentRow>;
  row: MRT_Row<EquipmentRow>;
  internalEditComponents: ReactNode[];
  headline: string;
}) {
  const createOrEditDialogStyle = {
    height: "calc(-64px + 100vh)",
    maxHeight: "calc(-64px + 100vh)",
    maxWidth: "50vw",
    position: "absolute",
    right: 0,
    margin: 0,
    width: "30vw",
    padding: 3,
    paddingTop: 5,
    top: "64px"
  };

  return (
    <Dialog
      open
      data-testid="equip_edit_row_dialog"
      PaperProps={{ sx: { ...createOrEditDialogStyle } }}
    >
      {/* edit equipment dialog contents */}
      <DialogTitle variant="h4">{headline}</DialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: "column", gap: "1.5rem", mt: 5 }}>
        <Grid container data-testid="edit-equip-dialog-contents" columnGap={4}>
          <Grid container item xs={12} rowGap={3.5}>
            {/* name */}
            {internalEditComponents[1]}
            {/* description now, instead of image url */}
            {internalEditComponents[2]}
            {/* category */}
            {internalEditComponents[3]}
            {/* assetRefId */}
            {internalEditComponents[4]}
            {/* location */}
            {internalEditComponents[5]}
            {/* floor */}
            {internalEditComponents[6]}
            {/* room/zone */}
            {internalEditComponents[7]}
            {/* place */}
            {internalEditComponents[8]}
            {/* cost per hour */}
            {internalEditComponents[9]}
          </Grid>
        </Grid>
      </DialogContent>
      <Divider sx={{ mt: 3, mb: 3 }} />
      <DialogActions>
        <MRT_EditActionButtons variant="text" table={table} row={row} />
      </DialogActions>
    </Dialog>
  );
}

export const renderSimpleCellName = (name?: string) => <Typography>{name ?? ""}</Typography>;

export const renderCellBookable = (row: MRT_Row<EquipmentRow>) => (
  <Typography>{row.original.bookable ? t("Yes") : t("No")}</Typography>
);
