import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  Popover,
  Stack,
  Switch,
  CardHeader,
  CardContent,
  Collapse,
  Avatar,
  IconButton,
  useTheme,
  Divider
} from "@mui/material";
import { CloudUpload, Edit, ExpandMore } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { FileObject } from "react-mui-dropzone";
import { TwitterPicker } from "react-color";
import { CheckinAndMaxAdvancedBooking, MetaData } from "../Login/typings/login.types";
import LogoUploadDialog from "./logo-upload-dialog.component";
import HomeOfficePolicy from "./home-office-policy.component";
import LabeledSlider from "../../components/Common/LabeledSlider/LabeledSlider";
import { useRemoteSetMetadata } from "../../hooks/Remote/Metadata/useRemoteSetMetadata";
import {
  displayValueForAllowedNoBookingValue,
  filterEnabledPolicy,
  generateAdvancedBookingSettings,
  initialCheckinEnableGrp,
  initializeCheckinSettingAdvancedBookingTime
} from "./functions/company-card.component.functions";
import { StyledCardRoot } from "./admin-styled.component";
import { CompanyCardCheckinPolicy } from "./company-card-checkin-policy.component";
import { CompanyMaximumAdvancedBookingSettingPerType } from "./company-card-maximumAdvancedBooking-perType.component";
import { useFeatures } from "../../components/FacilityManager/Hooks/useFeatures";

export interface CheckinPolicyGroup {
  workplace: boolean;
  parkingplace: boolean;
  electricchargingplace: boolean;
  conferencezone: boolean;
}

interface Props {
  brandColor?: string | null;
  companyName?: string | null;
  logoUrl?: string | null;
  companyMetaData: MetaData;
}

export default function CompanyCard({
  brandColor = "",
  companyName = "",
  logoUrl = "",
  companyMetaData
}: Props) {
  const {
    id,
    bookingsOnSundaysAllowed,
    bookingsOnSaturdaysAllowed,
    calendarSyncPossible,
    companyId,
    bookingNormHours,
    advancedBookingSettings,
    maxAllowedNoBookingTime,
    bookingAssistenceActive,
    checkinSettings
  } = companyMetaData;

  const { useShowPastBookingColleagues } = useFeatures();

  const { t } = useTranslation();
  const theme = useTheme();

  const [expanded, setExpanded] = useState(false);
  const [logoPath, setLogoPath] = useState("");

  const [checkinEnabledGrp, setCheckinEnabledGrp] = useState<CheckinPolicyGroup>({
    workplace: false,
    parkingplace: false,
    electricchargingplace: false,
    conferencezone: false
  });
  const [imageUpload, setImageUpload] = useState(false);
  const [logoFile, setLogoFile] = useState<FileObject[] | undefined>(undefined);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [homeOffice, setHomeOffice] = useState<{
    homeOfficeDays: [number, number];
    enabled: boolean;
    mandatory: boolean;
  }>({
    homeOfficeDays: [companyMetaData.minMobileWorkingDays, companyMetaData.maxMobileWorkingDays],
    enabled: companyMetaData.mobileWorkingEnabled,
    mandatory: companyMetaData.homeOfficePolicyObligatory
  });

  const [wpSetting, setWpSetting] = useState<CheckinAndMaxAdvancedBooking>(
    {} as CheckinAndMaxAdvancedBooking
  );
  const [parkPlSetting, setParkPlSetting] = useState<CheckinAndMaxAdvancedBooking>(
    {} as CheckinAndMaxAdvancedBooking
  );
  const [eChargePlSetting, setEChargePlSetting] = useState<CheckinAndMaxAdvancedBooking>(
    {} as CheckinAndMaxAdvancedBooking
  );
  const [confZnSetting, setConfZnSetting] = useState<CheckinAndMaxAdvancedBooking>(
    {} as CheckinAndMaxAdvancedBooking
  );

  const [newValues, setNewValues] = useState<MetaData>({} as MetaData);
  const openEl = Boolean(anchorEl);

  const { mutate: submitMetadata } = useRemoteSetMetadata({ mode: "Edit" });

  const handleExpandClick = () => {
    /* istanbul ignore next */
    setExpanded(!expanded);
  };

  useEffect(() => {
    const { initialWpSetting, initialParkSetting, initialElecSetting, initialConfSetting } =
      initializeCheckinSettingAdvancedBookingTime(checkinSettings, advancedBookingSettings);

    // set initial setting per booking type
    setWpSetting(initialWpSetting);
    setParkPlSetting(initialParkSetting);
    setEChargePlSetting(initialElecSetting);
    setConfZnSetting(initialConfSetting);

    // set initial checkin enabled
    setCheckinEnabledGrp(initialCheckinEnableGrp(checkinSettings));

    // set initial mobile working policy
    setHomeOffice({
      homeOfficeDays: [companyMetaData.minMobileWorkingDays, companyMetaData.maxMobileWorkingDays],
      enabled: companyMetaData.mobileWorkingEnabled,
      mandatory: companyMetaData.homeOfficePolicyObligatory
    });

    // update values
    setNewValues(prevState => ({
      ...prevState,
      color: brandColor,
      advancedBookingSettings,
      bookingNormHours,
      bookingsOnSundaysAllowed,
      bookingsOnSaturdaysAllowed,
      calendarSyncPossible,
      companyId: companyId,
      logoPath: logoUrl,
      maxAllowedNoBookingTime,
      bookingAssistenceActive,
      checkinSettings
    }));
  }, [
    bookingsOnSaturdaysAllowed,
    bookingsOnSundaysAllowed,
    calendarSyncPossible,
    brandColor,
    advancedBookingSettings,
    bookingNormHours,
    logoUrl,
    companyId,
    maxAllowedNoBookingTime,
    bookingAssistenceActive,
    checkinSettings
  ]);

  return (
    <StyledCardRoot>
      <CardHeader
        avatar={
          <Avatar
            alt={companyName || ""}
            src={newValues.logoPath || logoUrl || ""}
            aria-label="recipe"
            onClick={() => setImageUpload(true)}
            sx={{ backgroundColor: "white", cursor: "pointer" }}
          >
            <CloudUpload />
          </Avatar>
        }
        action={
          <IconButton
            data-testid={"expandButton"}
            sx={{
              transform: expanded ? "rotate(0deg)" : "rotate(180deg)",
              marginLeft: "auto",
              transition: theme.transitions.create("transform", {
                duration: theme.transitions.duration.shortest
              })
            }}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
            size="large"
          >
            <ExpandMore />
          </IconButton>
        }
        title={companyName}
        subheader={
          <Grid
            container
            justifyContent={"flex-start"}
            alignItems={"center"}
            style={{ width: "100%" }}
          >
            <Box
              style={{
                background: newValues.color || brandColor || "",
                width: "20%",
                height: 10,
                borderRadius: 6,
                marginRight: 5
              }}
            />
            <IconButton
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget)}
              size={"small"}
              data-testid={"color"}
              aria-label={"change Color"}
            >
              <Edit />
            </IconButton>
          </Grid>
        }
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Divider sx={{ width: "94%", margin: "0% 3%" }} />
        <CardContent>
          <Stack>
            <CompanyMaximumAdvancedBookingSettingPerType
              wpSetting={wpSetting}
              setWpSetting={setWpSetting}
              parkPlSetting={parkPlSetting}
              setParkPlSetting={setParkPlSetting}
              eChargePlSetting={eChargePlSetting}
              setEChargePlSetting={setEChargePlSetting}
              confZnSetting={confZnSetting}
              setConfZnSetting={setConfZnSetting}
              setNewValues={setNewValues}
            />

            <LabeledSlider
              marginRight="5px"
              value={newValues.bookingNormHours || bookingNormHours || 8}
              label={t("defaultBookingHours")}
              onChange={(e, v) => {
                /* istanbul ignore next */
                setNewValues(prevState => ({
                  ...prevState,
                  bookingNormHours: v as number
                }));
              }}
              min={1}
              max={15}
              step={1}
              ariaLabel={"defaultBookingHours"}
              data-testid={"defaultBookingHours"}
            />
            <LabeledSlider
              marginRight="5px"
              data-testid={"allowedNoBookingTimeSlider"}
              value={displayValueForAllowedNoBookingValue(
                newValues.maxAllowedNoBookingTime,
                maxAllowedNoBookingTime
              )}
              label={t("AllowedNoBookingTimeLabel")}
              onChange={(e, v) =>
                /* istanbul ignore next */
                setNewValues(prevState => ({
                  ...prevState,
                  maxAllowedNoBookingTime: v as number
                }))
              }
              min={0}
              max={100}
              step={5}
              ariaLabel={"AllowedNoBookingTimeLabel"}
            />

            <FormGroup row>
              <FormControlLabel
                control={
                  <Switch
                    checked={newValues.bookingsOnSaturdaysAllowed}
                    onChange={(e, c) => {
                      /* istanbul ignore next */
                      setNewValues(prevState => ({
                        ...prevState,
                        bookingsOnSaturdaysAllowed: c
                      }));
                    }}
                    name="saturdaybookings"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                }
                label={t("enableBookingsOnSaturday")}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={newValues.bookingsOnSundaysAllowed}
                    onChange={(e, c) => {
                      /* istanbul ignore next */
                      setNewValues(prevState => ({
                        ...prevState,
                        bookingsOnSundaysAllowed: c
                      }));
                    }}
                    name="sundaybookings"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                }
                label={t("enableBookingsOnSunday")}
              />
              {useShowPastBookingColleagues && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={newValues.showPastScheduleDataOfColleagues}
                      onChange={(e, c) => {
                        /* istanbul ignore next */
                        setNewValues(prevState => ({
                          ...prevState,
                          showPastScheduleDataOfColleagues: c
                        }));
                      }}
                      name="enableShowScheduleDataColleagues"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  }
                  label={t("enableShowScheduleDataColleagues")}
                />
              )}
              <FormControlLabel
                control={
                  <Switch
                    checked={newValues.calendarSyncPossible}
                    onChange={(e, c) => {
                      /* istanbul ignore next */
                      setNewValues(prevState => ({
                        ...prevState,
                        calendarSyncPossible: c
                      }));
                    }}
                    name="calendarSyncPossible"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                }
                label={t("calendarSyncPossible")}
              />

              {/* checkin policy by booking type top-drawer button */}
              <CompanyCardCheckinPolicy
                checkinEnabledGrp={checkinEnabledGrp}
                setCheckinEnabledGrp={setCheckinEnabledGrp}
                wpSetting={wpSetting}
                setWpSetting={setWpSetting}
                parkPlSetting={parkPlSetting}
                setParkPlSetting={setParkPlSetting}
                eChargePlSetting={eChargePlSetting}
                setEChargePlSetting={setEChargePlSetting}
                confZnSetting={confZnSetting}
                setConfZnSetting={setConfZnSetting}
                setNewValues={setNewValues}
                sliderStyle={{ marginRight: "5px", width: "85%" }}
              />

              {/* home office policy */}
              <HomeOfficePolicy homeOffice={homeOffice} setHomeOffice={setHomeOffice} />
            </FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={newValues.bookingAssistenceActive}
                  onChange={(e, c) => {
                    /* istanbul ignore next */
                    setNewValues(prevState => ({
                      ...prevState,
                      /* istanbul ignore next */
                      bookingAssistenceActive: c
                    }));
                  }}
                />
              }
              label={t("bookingAssistantActive")}
            />
            <Button
              variant="contained"
              disabled={!newValues}
              onClick={() => {
                /* istanbul ignore next */
                const data = new FormData();
                /* istanbul ignore next */
                data.append("currency", "EUR");
                /* istanbul ignore next */
                data.append(
                  "bookingsOnSaturdaysAllowed",
                  String(newValues.bookingsOnSaturdaysAllowed)
                );
                /* istanbul ignore next */
                data.append("bookingsOnSundaysAllowed", String(newValues.bookingsOnSundaysAllowed));
                /* istanbul ignore next */
                data.append(
                  "showPastScheduleDataOfColleagues",
                  String(newValues.showPastScheduleDataOfColleagues)
                );
                /* istanbul ignore next */
                data.append("calendarSyncPossible", String(newValues.calendarSyncPossible));
                /* istanbul ignore next */
                data.append("bookingsAreAnonymous", "true");
                /* istanbul ignore next */
                data.append("automatedWorkplaceBookingReflectionTime", "10");
                /* istanbul ignore next */
                data.append("maxAdvancedZoneBookingLength", "10");
                /* istanbul ignore next */
                data.append("companyId", JSON.stringify(companyId));
                /* istanbul ignore next */
                data.append("timezone", "Europe/Berlin");
                /* istanbul ignore next */
                data.append("color", String(newValues.color));
                /* istanbul ignore next */
                data.append("id", String(id ? Number(id) : 1));
                /* istanbul ignore next */
                logoFile && data.append("file", logoFile[0].file);
                /* istanbul ignore next */
                data.append("bookingNormHours", String(newValues.bookingNormHours));
                /* istanbul ignore next */
                data.append("maxAllowedNoBookingTime", String(newValues.maxAllowedNoBookingTime));
                /* istanbul ignore next */
                data.append("homeOfficePolicyObligatory", String(homeOffice.mandatory));
                /* istanbul ignore next */
                data.append("mobileWorkingEnabled", String(homeOffice.enabled));
                /* istanbul ignore next */
                data.append("minMobileWorkingDays", String(homeOffice.homeOfficeDays[0]));
                /* istanbul ignore next */
                data.append("maxMobileWorkingDays", String(homeOffice.homeOfficeDays[1]));
                /* istanbul ignore next */
                data.append("bookingAssistenceActive", String(newValues.bookingAssistenceActive));
                /* istanbul ignore next */
                data.append(
                  "checkinSettings",
                  JSON.stringify(
                    filterEnabledPolicy({
                      checkinEnabledGrp,
                      wpSetting,
                      parkPlSetting,
                      eChargePlSetting,
                      confZnSetting
                    })
                  )
                );
                /* istanbul ignore next */
                data.append(
                  "advancedBookingSettings",
                  JSON.stringify(
                    generateAdvancedBookingSettings(
                      wpSetting,
                      parkPlSetting,
                      eChargePlSetting,
                      confZnSetting
                    )
                  )
                );

                submitMetadata(data);
              }}
            >
              {t("Confirm")}
            </Button>
          </Stack>
        </CardContent>
      </Collapse>
      <LogoUploadDialog
        logoFile={logoFile}
        setLogoFile={setLogoFile}
        imageUpload={imageUpload}
        setImageUpload={setImageUpload}
        setLogoPath={setLogoPath}
        logoPath={logoPath}
      />
      <Popover
        open={openEl}
        anchorEl={anchorEl}
        /* istanbul ignore next */
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
      >
        <Grid container style={{ marginTop: 10, height: 100 }}>
          <TwitterPicker
            color={newValues.color || brandColor || ""}
            onChange={props =>
              /* istanbul ignore next */
              setNewValues(prevState => ({
                ...prevState,
                color: props.hex
              }))
            }
          />
        </Grid>
      </Popover>
    </StyledCardRoot>
  );
}
