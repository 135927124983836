import { Button, Typography } from "@mui/material";
import { DateTime } from "luxon";
import {
  getFloorPlanByWorkplaceInventoryId,
  getFloorPlanByZoneInventoryId
} from "../../utils/axios-requests";

interface P {
  params: {
    placeInventoryId?: number | null;
    zoneInventoryId?: number | null;
    floorName?: string;
    locationName?: string;
  };
  noPlaceButtonText: string;
  showButtonText: string;
  setSelectedInventoryId: (scheduleId: number) => void;
  setFloorPlan: (r: any) => void;
  setOpen: (bool: boolean) => void;
}

const ShowAssignedFloor = ({
  params,
  noPlaceButtonText,
  showButtonText,
  setSelectedInventoryId,
  setFloorPlan,
  setOpen
}: P) => {
  const { placeInventoryId, zoneInventoryId } = params;

  // fetch the floor plan by its inventory id and show floor plan modal
  const showFloorByInventoryId = (): void => {
    // when the item is place
    if (placeInventoryId && placeInventoryId !== null) {
      setSelectedInventoryId(placeInventoryId);
      getFloorPlanByWorkplaceInventoryId(placeInventoryId, {
        start: DateTime.now().toISO(),
        end: DateTime.now().plus({ minutes: 1 }).toISO()
      }).then(r => {
        setFloorPlan(r.data);
        setOpen(true);
      });
    }

    // when the item is zone
    if (zoneInventoryId && zoneInventoryId !== null) {
      setSelectedInventoryId(zoneInventoryId);
      getFloorPlanByZoneInventoryId(zoneInventoryId, {
        start: DateTime.now().toISO(),
        end: DateTime.now().plus({ minutes: 1 }).toISO()
      }).then(r => {
        setFloorPlan(r.data);
        setOpen(true);
      });
    }
  };

  return (
    <>
      {/* when the item is not assigned */}
      {!params.floorName && !params.locationName && (
        <Typography data-testid={`${noPlaceButtonText}-btn`}>{noPlaceButtonText}</Typography>
      )}

      {/* when the item is assigned */}
      {(placeInventoryId !== null || zoneInventoryId !== null) &&
        params.floorName &&
        // (params.status === EquipmentStatus.PLACED || EquipmentStatus.BOOKED)
        params.locationName && (
          <Button
            data-testid={"show-assigned-floor"}
            onClick={showFloorByInventoryId}
            size={"small"}
            color={"primary"}
          >
            {showButtonText}
          </Button>
        )}
    </>
  );
};

export default ShowAssignedFloor;
