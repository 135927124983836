import React, { FunctionComponent } from "react";
import { Chip, Grid } from "@mui/material";
import { Add, Done } from "@mui/icons-material";
import { NotSerializedBookingInputs } from "../../features/Booking-Form/typings/booking-inputs";

interface OwnProps {
  inputs: NotSerializedBookingInputs;
  currentUser: number;
  setCurrentUser: React.Dispatch<React.SetStateAction<number>>;
}

type Props = OwnProps;

const FormWorkplaceTeamBookingChips: FunctionComponent<Props> = ({
  inputs,
  currentUser,
  setCurrentUser
}) => {
  return (
    <>
      <Grid data-testid="team-member-chips-container" container>
        {inputs.mode === "team" &&
          inputs.usersBookedFor?.map((user, index) => (
            <Chip
              data-testid="team-member"
              label={user.firstName + " " + user.surname}
              style={{ margin: "0 10px 10px 0" }}
              clickable
              key={user.userId}
              color={currentUser === index ? "primary" : "default"}
              onClick={() => setCurrentUser(index)}
              icon={user.bookingInventoryId === undefined ? <Add /> : <Done />}
            />
          ))}
      </Grid>
    </>
  );
};

export default FormWorkplaceTeamBookingChips;
