import { Box, IconButton } from "@mui/material";
import { EditCalendar, Lock, LockOutlined } from "@mui/icons-material";
import { PermissionUnitEntries } from "../PermissionSelect";

type PermissionSelectSingleChipProps = {
  option: PermissionUnitEntries;
  handleStrictPermPopoverOpen: (event: React.MouseEvent<HTMLElement>) => void;
  handleStrictPermPopoverClose: () => void;
  handleWkdayPermPopoverOpen: (event: React.MouseEvent<HTMLElement>) => void;
  handleWkdayPermPopoverClose: () => void;
  handleStricPermChange: (e: any, value: any) => void;
  setSelectOpt: (o: PermissionUnitEntries | null) => void;
};

export function PermissionSelectSingleChip(props: PermissionSelectSingleChipProps) {
  const {
    option,
    handleStrictPermPopoverOpen,
    handleStrictPermPopoverClose,
    handleWkdayPermPopoverOpen,
    handleWkdayPermPopoverClose,
    handleStricPermChange,
    setSelectOpt
  } = props;

  return (
    <Box data-testid="perm-type-btn-group" sx={{ pl: 0.7 }}>
      <IconButton
        data-testid="stric-perm-btn"
        sx={{ padding: 0.1 }}
        onMouseOver={handleStrictPermPopoverOpen}
        onMouseLeave={handleStrictPermPopoverClose}
        onClick={e => handleStricPermChange(e, option)}
      >
        {option.enforceUsage ? <Lock color={"info"} /> : <LockOutlined />}
      </IconButton>

      {/* a button to open the weekday permission modal open */}
      <IconButton
        data-testid="weekday-perm-modal-open-btn"
        sx={{ padding: 0.1, marginRight: "-5px" }}
        onMouseOver={handleWkdayPermPopoverOpen}
        onMouseLeave={handleWkdayPermPopoverClose}
        onClick={() => setSelectOpt(option)}
      >
        <EditCalendar />
      </IconButton>
    </Box>
  );
}
