import { DisplayViewport } from "../../DisplayViewport/DisplayViewport";
import { PropsWithChildren, useEffect, useState } from "react";
import { Sprite } from "@pixi/react";
import { Point, Texture, Resource } from "pixi.js";
import { IFloorPayload } from "../../../Domain/Types/FloorPlan/FloorPayload.type";
import { ZoneContainer } from "../../Zone/ZoneContainer";
import { PlaceContainer } from "../../Place/PlaceContainer";
import { ISeatStatus } from "../../../Domain/Types/FloorPlan/SeatStatus.type";
import { generatePlaceVariant } from "../../../Functions/generateVariant";
import JumpIcon from "../../Canvas/Partials/jump-icon.component";
import { ICoordinate } from "../../../Domain/Types/FloorPlan/Coordinate.type";
import { useGeometry } from "../../../Hooks/useGeometry";
import { IViewport } from "../../../Domain/Types/FloorPlan/Viewport.type";
import { useSelector } from "../../../../../app/helpers";
import { RootState } from "../../../../../app/rootReducer";
import { generateInitial } from "../SelectFloorPlanView/Functions/SelectFloorPlanView.functions";
import Position from "../../../Domain/Types/Position.type";
import { PlanViewMode } from "../../PixiViewport/PixiViewport";

interface Props {
  name: string;
  floorPlan: IFloorPayload;
  seatStatus: ISeatStatus;
  background: Texture<Resource>;
  initialPos?: ICoordinate;
}

export function DisplayFloorPlanView({
  children,
  name,
  background,
  floorPlan,
  seatStatus
}: PropsWithChildren<Props>) {
  const { usersBookedFor } = useSelector((state: RootState) => state.booking.inputs);
  const { userInformation } = useSelector((state: RootState) => state.login);
  const { color: companyColor } = useSelector(
    (state: RootState) => state.login.userInformation.company.meta
  );

  const { convertPixiPoints, centroid } = useGeometry();
  const initialPos = generateInitialPos(floorPlan, seatStatus, convertPixiPoints, centroid);

  const [viewportDimensions, setViewportDimensions] = useState<IViewport>({ width: 0, height: 0 });
  const [placeScale, setPlaceScale] = useState(0);
  const [zoomToHighlight, setZoomToHighlight] = useState(false);

  const jumpIconColor = companyColor ? companyColor : "#00d084";

  useEffect(() => {
    if (!background) return;
    setPlaceScale(background.width / floorPlan.viewport.width);
  }, [background]);

  return (
    <DisplayViewport
      planViewMode={PlanViewMode.DISPLAY}
      floorPlanName={name}
      initialZoomEnd={new Point(background.width, background.height)}
      currentFloorPlan={undefined}
      placeScale={placeScale}
      initialPos={initialPos}
      zoomToHighlight={zoomToHighlight}
      viewportDimensions={viewportDimensions}
      setViewportDimensions={setViewportDimensions}
      toolbars={
        <div
          onClick={e => onJumpButtonClick(e, zoomToHighlight, setZoomToHighlight)}
          data-testid={"cbw-jump"}
          style={{ position: "relative", zIndex: 1 }}
        >
          <JumpIcon brandColor={jumpIconColor} toggleZoomHighlight={zoomToHighlight} />
        </div>
      }
    >
      <Sprite texture={background} />

      {/** draw zones */}
      {floorPlan.zones.map(zone => (
        <ZoneContainer
          key={zone.id}
          id={zone.id}
          inventoryId={zone.inventoryId || zone.inventory?.id}
          walls={convertPixiPoints(zone.coordinates)}
          zoneType={zone.zoneTypeId || zone.inventory?.zoneTypeId || 1}
          disabled={!seatStatus.availableList.includes(zone.inventory?.id || zone.inventoryId)}
        />
      ))}

      {/** draw places */}
      {!!placeScale &&
        floorPlan.places.map(workplace => (
          <PlaceContainer
            key={workplace.id}
            boundingBox={{
              width: workplace.inventory?.boundingBox?.width ?? 160,
              height: workplace.inventory?.boundingBox?.height ?? 80
            }}
            id={workplace.id}
            inventoryId={workplace.inventoryId || workplace.inventory?.id}
            rotation={workplace.rotate}
            position={workplace.position}
            variant={generatePlaceVariant({ workplace: workplace, zone: undefined }, seatStatus)}
            isSelectable={false}
            placeTypeId={workplace.placeTypeId || workplace.inventory?.placeTypeId}
            placeScale={placeScale}
            icon={generateInitial(
              name,
              usersBookedFor,
              userInformation,
              [],
              workplace.inventoryId || workplace.inventory?.id
            )}
          />
        ))}

      {children}
    </DisplayViewport>
  );
}

export function onJumpButtonClick(
  event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  zoomToHighlight: boolean,
  setZoomToHighlight: (value: boolean) => void
) {
  event.preventDefault();
  if (zoomToHighlight) {
    setZoomToHighlight(false);
  } else {
    setZoomToHighlight(true);
  }
}

export function generateInitialPos(
  floorPlan: IFloorPayload,
  seatStatus: ISeatStatus,
  convertPixiPoints: (points: Position[]) => Point[],
  centroid: (points: Point[]) => Point
) {
  const initialPlacePos = floorPlan.places.find(wp =>
    seatStatus.availableList.includes(wp.inventoryId)
  )?.position;

  if (initialPlacePos) return initialPlacePos;

  const initialZoneCoordi = floorPlan.zones.find(zn =>
    seatStatus.availableList.includes(zn.inventory?.id || zn.inventoryId)
  )?.coordinates;

  if (initialZoneCoordi) {
    const coordi = convertPixiPoints(initialZoneCoordi);
    const centerPos = centroid(coordi);
    return centerPos;
  }
}
