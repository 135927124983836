import { TFunction, t } from "i18next";
import { MRT_Row } from "material-react-table";
import jsPDF from "jspdf";
import autoTable, { RowInput } from "jspdf-autotable";
import i18n from "../../i18n";
import { enqueueSnackbar } from "../../app/snackbar.slice";
import { deleteEquipment } from "../../utils/axios-requests";
import { IPlaceEquipments } from "../FacilityManager/Domain/Types/FloorPlan/Equipment.type";
import {
  EquipmentRow,
  EquipmentRowValues
} from "./FilterableEquipmentList/typings/equipment-row.types";
import { SetStateAction } from "react";

export type EquipmentCategory = {
  id: number;
  name: string;
  description?: string | null;
  tenantId?: number | null;
};

export type EquipmentCategories = {
  equipmentCategories: EquipmentCategory[];
  setDeviceCategories?: React.Dispatch<React.SetStateAction<EquipmentCategory[]>>;
};

export interface Row {
  name: string;
  costPerHour: number;
  uid: string;
  assetRefId?: string;
  id: number;
  locationName?: string;
  floorName?: string;
  equipmentCategory?: EquipmentCategory;
  placeInventoryId?: number | null;
  zoneInventoryId?: number | null;
}

export const rejectFile = (e: File[]) => {
  if (e[0].size > 2000000) {
    enqueueSnackbar(i18n.t("The file can not be bigger than 2MB"), { variant: "error" });
  } else {
    enqueueSnackbar(i18n.t("This is not a valid file"), { variant: "error" });
  }
};

export const validateName = (rowData: { name: string }) =>
  rowData.name ? true : i18n.t("Name cannot be empty");

export const validateCPH = (rowData: { costPerHour: number }) =>
  rowData.costPerHour >= 0 ? true : i18n.t("Cost per hour must be bigger than 0");

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const validateCategory = (rowData: { equipmentCategory?: { id: number } }) => true;

export const handleClickTable = (event: any, dataClick: Row | Row[], data: Row[] | undefined) => {
  if (data && Array.isArray(dataClick)) {
    deleteEquipment(dataClick.map(clickData => clickData.id))
      .then(() => {
        window.location.reload();
        enqueueSnackbar(i18n.t("Successfully deleted a device"), { variant: "success" });
      })
      .catch(() => {
        enqueueSnackbar(i18n.t(`There was an error deleting devices`), {
          variant: "error"
        });
      });
  }
};

export const validateDescription = (rowData: { description: string }) =>
  rowData.description ? true : i18n.t("Description cannot be empty");

export const validateCostFactor = (rowData: { costFactor: number }) => {
  return rowData.costFactor && rowData.costFactor > 0
    ? true
    : i18n.t("Cost Factor must be bigger than 0");
};

export const validateRequired = (value: string | undefined) => !!value && !!value.length;
export const validateCostPH = (value: number | undefined) => !!value && value >= 0;
export const validateInventoryId = (value: number | null | string | undefined) => {
  if (!value) return true;
  if (value && !Number.isNaN(Number(value)) && Number.isInteger(Number(value))) return true;

  return false;
};

export const validateLocationAndFloor = (
  row: Partial<EquipmentRowValues>,
  item: string | null | undefined,
  title: string
) => {
  return (row.zoneInventoryId || row.placeInventoryId) && !item
    ? t(`Please select the ${title}`)
    : "";
};

export function validateEquipmentRow(row: Partial<EquipmentRowValues>) {
  // place or zone assignment is allowed only unassigned or only one assigned
  const isAssignedWrong = !!row.placeInventoryId && !!row.zoneInventoryId;
  const isAssignedWrongErrTxt = isAssignedWrong
    ? t("Equipment can be assigned either to a place or zone")
    : "";

  return {
    name: !validateRequired(row.name) ? t("Name cannot be empty") : "",
    costPerHour: !validateCostPH(row.costPerHour) ? t("Cost per hour must be bigger than 0") : "",
    assetRefId: !validateRequired(row.assetRefId) ? t("Asset Id cannot be empty") : "",
    placeInventoryId: !validateInventoryId(row.placeInventoryId)
      ? t("Place Inventory Id must be number")
      : isAssignedWrongErrTxt,
    zoneInventoryId: !validateInventoryId(row.zoneInventoryId)
      ? t("Zone Inventory Id must be number")
      : isAssignedWrongErrTxt,
    // status: !row.status ? t("Status cannot be empty") : "",
    // bookable: !validateRequired(row.bookable?.toString()) ? t("Bookable cannot be empty") : "",
    location: validateLocationAndFloor(row, row["location.name"], "location"),
    floor: validateLocationAndFloor(row, row["floor.name"], "floor")
  };
}

export const customFilterSearchBooking = (
  term: string,
  rowData: { id: number },
  placeEquipments: IPlaceEquipments[]
) => {
  const id = placeEquipments.filter(e => e.equipmentId === rowData.id)[0]?.placeInventoryId;
  return rowData.id === Number(term) && id;
};

export const customSearchRender = (
  params: { id: number; uid: string },
  placeEquipments?: IPlaceEquipments[]
) => {
  return placeEquipments?.filter(e => e.equipmentId === params.id)[0]?.placeInventoryId;
};

export const customFilterSearchPlaceBooking = (
  term: string,
  rowData: { id: number; placeInventoryId?: number | null }
) => {
  if (rowData.placeInventoryId && rowData.placeInventoryId !== null) {
    return rowData.placeInventoryId.toString().includes(term.toLowerCase());
  }
  return false;
};

export const customFilterSearchZoneBooking = (
  term: string,
  rowData: { id: number; zoneInventoryId?: number | null }
) => {
  if (rowData.zoneInventoryId && rowData.zoneInventoryId !== null) {
    return rowData.zoneInventoryId.toString().includes(term.toLowerCase());
  }
  return false;
};

export const customSearchRenderForPlaceId = (params: {
  id: number;
  uid: string;
  placeInventoryId?: number | null;
}) => {
  if (params.placeInventoryId && params.placeInventoryId !== null) return params.placeInventoryId;
  return undefined;
};

export const customSearchRenderForZoneId = (params: {
  id: number;
  uid: string;
  zoneInventoryId?: number | null;
}) => {
  if (params.zoneInventoryId && params.zoneInventoryId !== null) return params.zoneInventoryId;
  return undefined;
};

export const highlightedDesk = (selectedWorkplaceInventoryId?: number) => {
  return selectedWorkplaceInventoryId ? [selectedWorkplaceInventoryId] : [];
};

export const handleExportRows = (rows: MRT_Row<EquipmentRow>[], t: TFunction) => {
  const doc = new jsPDF({ orientation: "landscape" });
  const tableData = rows.map(row => Object.values(row.original));

  const tableHeader = [
    t("Id"),
    t("Name"),
    t("location"),
    t("floor"),
    t("Cost (h)"),
    t("Category Name"),
    t("Place Id"),
    t("Zone Id"),
    t("Asset Id")
  ];

  const pdfTableData = tableData.map(d => {
    const d8 = d[8] as EquipmentCategory;

    return [d[4], d[0], d[6], d[7], d[1], d8.name, d[9], d[10], d[3]];
  });

  autoTable(doc, {
    head: [tableHeader],
    body: pdfTableData as RowInput[],
    rowPageBreak: "avoid",
    theme: "striped",
    columnStyles: { 0: { minCellWidth: 15 } }
  });

  doc.save(`${t("devices")}.pdf`);
};

export const checkTargetValError = (
  value: string,
  item: string,
  validationErrors: Record<string, string | undefined>,
  setValidationErrors: (value: SetStateAction<Record<string, string | undefined>>) => void
) => {
  if (value === "") {
    setValidationErrors({
      ...validationErrors,
      [item]: undefined
    });
  }
};

export const checkTargetValueInventoryId = (
  value: string,
  item: string,
  itemErrDesc: string,
  validationErrors: Record<string, string | undefined>,
  setValidationErrors: (value: SetStateAction<Record<string, string | undefined>>) => void,
  setTargetValueInventoryId: (value: number) => void
) => {
  if (value) {
    setValidationErrors({
      ...validationErrors,
      [item]: undefined
    });
    if (validateInventoryId(value)) setTargetValueInventoryId(Number(value));
    else {
      setValidationErrors({
        ...validationErrors,
        [item]: itemErrDesc
      });
    }
  }
};
