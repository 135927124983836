import { useEffect, useState } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
  MRT_Row,
  MRT_GlobalFilterTextField,
  MRT_ToggleFiltersButton
} from "material-react-table";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import ShowWorkplaceButton from "../../components/EquipmentList/show-workplace.component";
import { FmDeviceDialog as BookingPlanDialog } from "../../components/EquipmentList/FilterableEquipmentList/FmDeviceDialog/FmDeviceDialog";
import usePlaceApprovalColumns from "./hooks/usePlaceApprovalColumns";
import { useRemoteInactivateBookings } from "../../hooks/Remote/Booking/useRemoteInactivateBookings";
import { useRemoteFetchUserApprovalPlaceBookings } from "../../hooks/Remote/Approval/useRemoteFetchUserApprovalPlaceBookings";
import { BookingType } from "../Booking-Form/typings/booking-inputs";
import useTimeFormat from "../../hooks/useTimeFormat/useTimeFormat";
import { handleMultiDeleteBookings } from "../Schedule/schedule.functions";
import { BookingApproval } from "./typings/booking-approval-type";
import { commonLocalization } from "../../functions/tableLocalization";
import { BookingApprovalTopToolbar as PlaceBookingApprovalTopToolbar } from "./booking-approval-toptoolbar.component";
import TableDeleteConfirmDialog from "../../components/Common/TableDeletionConfirmDialog/TableDeletionConfirmDialog.component";

export type PlaceApprovalRow = {
  bookingId: number;
  bookingType: BookingType;
  scheduleId: number;
  inventoryId: number;
  userBookedFor: string;
  start: string;
  end: string;
  timezone: string;
  weekday: string;
  locationName: string;
  floorName: string;
  categoryName: string;
};
export interface PlaceApprovalTableState {
  columns: Array<MRT_ColumnDef<PlaceApprovalRow>>;
}

/**
 * a table to display all desk bookings.
 * A supervisor can reject a booking here.
 * @constructor
 */
function PlaceBookingApproval() {
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  const { dateFormat, timeFormat } = useTimeFormat();
  const localeFullFormat = dateFormat + " " + timeFormat;

  const [data, setData] = useState<PlaceApprovalRow[]>();
  const [open, setOpen] = useState<boolean>(false);
  const [selectedWorkplaceInventoryId, setSelectedWorkplaceInventoryId] = useState<number>();
  const [floorPlan, setFloorPlan] = useState<any>();
  const [columns, setState] = useState<PlaceApprovalTableState>();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const { data: approvalData, refetch: refetchApprovalData } =
    useRemoteFetchUserApprovalPlaceBookings();
  const { mutate: inactivateBookings, status: inactivateBookingsStatus } =
    useRemoteInactivateBookings(t("Successfully rejected the workplace(s)"));

  const translatedMobileWorking = t("Mobile Working").toUpperCase();
  const checkMobileWorking = (str: string) =>
    str === "Mobile Working" ? t("Mobile Working") : str;

  // Button to display a workplace in the Facility Manager
  const renderButton = (params: MRT_Row<PlaceApprovalRow>["original"]) => {
    if (
      (params.locationName.toUpperCase() ||
        params.floorName.toUpperCase() ||
        params.categoryName.toUpperCase()) === translatedMobileWorking
    )
      return null;

    return (
      <ShowWorkplaceButton
        setSelectedWorkplaceInventoryId={setSelectedWorkplaceInventoryId}
        inventoryId={params.inventoryId}
        params={{
          id: params.bookingId,
          locationName: params.locationName,
          floorName: params.floorName
        }}
        setFloorPlan={setFloorPlan}
        setOpen={setOpen}
      />
    );
  };

  function handleInactiveBookings(selected: BookingApproval[]) {
    handleMultiDeleteBookings({
      selectedSchedules: selected.map(s => ({
        id: s.bookingId,
        bookingType: s.bookingType
      })),
      inactivateBookings
    });
  }

  const fetchColumns = usePlaceApprovalColumns({ renderButton, dateFormat, localeFullFormat });

  useEffect(() => {
    setState(() => fetchColumns());
  }, [i18n.language]);

  useEffect(() => {
    if (inactivateBookingsStatus === "success") {
      setIsDeleteModalOpen(false);
      refetchApprovalData();
    }

    if (inactivateBookingsStatus === "error") {
      enqueueSnackbar(t("There was an error rejecting the workplace(s)"), { variant: "error" });
    }
  }, [inactivateBookingsStatus]);

  useEffect(() => {
    if (!approvalData) return;

    setData(
      approvalData.map(d => ({
        ...d,
        floorName: checkMobileWorking(d.floorName),
        workplaceCategory: checkMobileWorking(d.categoryName),
        locationName: checkMobileWorking(d.locationName),
        weekday: t(d.weekday)
      }))
    );
  }, [approvalData, i18n.language]);

  const table = useMaterialReactTable({
    columns: columns?.columns ?? [],
    data: data ?? [],
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableRowSelection: true,
    initialState: {
      showColumnFilters: true,
      showGlobalFilter: true
    },
    muiSearchTextFieldProps: {
      variant: "filled",
      inputProps: {
        style: {
          paddingTop: "12px",
          paddingBottom: "10px"
        }
      }
    },
    localization: {
      ...commonLocalization(t),
      noRecordsToDisplay: t("There are no bookings of your employees yet.")
    },
    positionToolbarAlertBanner: "none",
    renderTopToolbar: ({ table }) => {
      return (
        <Box
          sx={{
            display: "flex",
            gap: "0.5rem",
            p: "8px",
            justifyContent: "end"
          }}
        >
          {/* import MRT sub-components */}
          <MRT_GlobalFilterTextField table={table} />
          <MRT_ToggleFiltersButton table={table} />

          {/* inactivate button */}
          <PlaceBookingApprovalTopToolbar
            disabled={table.getSelectedRowModel().flatRows.length <= 0}
            onClick={() => setIsDeleteModalOpen(true)}
          />

          {/* Confirm Deletion Modal */}
          <TableDeleteConfirmDialog
            dialogDesc={t("_tableDeletionConfirmationDesc")}
            isDeleteModalOpen={isDeleteModalOpen}
            setIsDeleteModalOpen={setIsDeleteModalOpen}
            onConfirm={() => {
              const selected = table.getSelectedRowModel().rows.map(row => row.original);
              handleInactiveBookings(selected);
            }}
            deleteStatus={inactivateBookingsStatus}
          />
        </Box>
      );
    }
  });

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MaterialReactTable table={table} />
        </LocalizationProvider>
      </Box>

      <BookingPlanDialog
        open={open}
        handleClose={() => setOpen(false)}
        isMobile={isMobile}
        floorPlan={floorPlan}
        selectedInventoryId={selectedWorkplaceInventoryId}
        dialogTitle="Booking Details"
      />
    </>
  );
}

export default PlaceBookingApproval;
