import React, { useEffect, useMemo, useState } from "react";
import { Autocomplete, Button, Grid, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IncomingSocketEvent, socket } from "../../../app/sockets/socket";
import InputDialogColleagues from "../../../components/Dialog/partials/input-dialog-colleagues.component";
import { InfoIconWithTooltip } from "../../../components/Title/InfoIcon";
import ColleagueCard from "../ColleagueCard/colleague-card.component";
import { useRemoteFetchColleaguesQuery } from "../hooks/useRemoteFetchColleaguesQuery";
import { Colleague } from "../types/Colleague.type";
import { checkColleagueInquiry } from "./checkColleagueInquiry.function";

/**
 * @description The colleagues card component renders the colleagues of the user.
 */

const Colleagues = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [colleagues, setColleagues] = useState<Colleague[]>([]);
  const [selectedColleagues, setSelectedColleagues] = useState<Colleague[]>();

  const { data: remoteColleagues, refetch } = useRemoteFetchColleaguesQuery();

  const filteredEmployees = useMemo(() => {
    const filteredColleagues = colleagues.filter(employee => {
      if (selectedColleagues?.length) {
        return selectedColleagues?.some(selectedEmployee => {
          return selectedEmployee.userId === employee.userId;
        });
      }
      return true;
    });

    const inquirying = filteredColleagues.filter(c => checkColleagueInquiry(c).isInquiry);
    const nonInquired = filteredColleagues.filter(c => !checkColleagueInquiry(c).isInquiry);

    return [...inquirying, ...nonInquired];
  }, [colleagues, selectedColleagues]);

  useEffect(() => {
    if (remoteColleagues) setColleagues(remoteColleagues);
  }, [remoteColleagues]);

  useEffect(() => {
    const handleConnectionsUpdated = async () => refetch();
    socket.on(IncomingSocketEvent.ConnectionsUpdated, handleConnectionsUpdated);
    return () => {
      socket.off(IncomingSocketEvent.ConnectionsUpdated, handleConnectionsUpdated);
    };
  }, [refetch]);

  return (
    <>
      <Grid container justifyContent={"space-between"} alignItems={"center"}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            marginBottom: 10
          }}
        >
          <InfoIconWithTooltip tooltipText={t("_addColleagueInfo")} />

          <Button
            data-testid="colleagues-add-btn"
            size="small"
            sx={{ marginLeft: 1 }}
            onClick={() => {
              setOpen(true);
            }}
          >
            {t("Add Colleagues")} +
          </Button>
        </div>
        <Autocomplete
          multiple
          disableCloseOnSelect
          style={{ width: 300 }}
          limitTags={1}
          data-testid={"colleague-find-autocomplete"}
          id="tags-outlined7"
          options={colleagues ? colleagues : []}
          filterSelectedOptions
          getOptionLabel={option => option.firstName + " " + option.surname}
          onChange={(event, values) => {
            event.persist();
            setSelectedColleagues(values);
          }}
          value={selectedColleagues || []}
          renderInput={params => (
            <TextField
              {...params}
              variant="outlined"
              size={"small"}
              fullWidth
              placeholder={t("Find your colleagues")}
            />
          )}
        />
      </Grid>
      {colleagues && (
        <>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            style={{
              paddingTop: "20px"
            }}
          >
            <InputDialogColleagues open={open} onClose={() => setOpen(false)} />
          </Grid>

          <Grid container>
            {filteredEmployees.map(member => (
              <ColleagueCard key={member.userId} member={member} onRefetch={() => refetch()} />
            ))}
          </Grid>
        </>
      )}
    </>
  );
};

export default Colleagues;
