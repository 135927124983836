import { useState } from "react";
import {
  Autocomplete,
  Chip,
  CircularProgress,
  Popover,
  TextField,
  Typography
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { PermsType } from "../../Partial/PermissionChange.partial";
import {
  RemoteCompanyPerm,
  RemoteCompanyUnitsPerm,
  RemoteUsersPerm
} from "../../../../Domain/Types/FloorPlan/Permission.type";
import {
  capitalize,
  checkOptEqual,
  checkOptLabel,
  generateLabel
} from "./PermissionSelect.functions";
import { WeekdayPermissionDialog } from "./WeekdayPermission/WeekdayPermissionDialog";
import { PermissionSelectSingleChip } from "./PermissionSelectSingleChip/PermissionSelectSingleChip";

export type PermissionUnitEntries = RemoteCompanyPerm | RemoteCompanyUnitsPerm | RemoteUsersPerm;

export function PermissionSelect({
  type,
  label,
  disabledRemoteOpt,
  initialValue,
  entries,
  handleChange,
  handleStricPermChange,
  handleActiveWkdayChange
}: {
  type: string;
  label: PermsType;
  disabledRemoteOpt: boolean;
  initialValue: PermissionUnitEntries[];
  entries: PermissionUnitEntries[];
  handleChange: (e: any, value: any[]) => void;
  handleStricPermChange: (e: any, value: any) => void;
  handleActiveWkdayChange: (option: any, value: any[]) => void;
}) {
  const { t } = useTranslation();

  const [selectOpt, setSelectOpt] = useState<PermissionUnitEntries | null>(null);

  // handle strict perm popover
  const [anchorElStrict, setAnchorElStrict] = useState<HTMLElement | null>(null);
  const strictOpen = Boolean(anchorElStrict);
  const handleStrictPermPopoverOpen = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorElStrict(event.currentTarget);
  const handleStrictPermPopoverClose = () => setAnchorElStrict(null);

  // handle weekday perm popover
  const [anchorElWkday, setAnchorElWkday] = useState<HTMLElement | null>(null);
  const wkdayOpen = Boolean(anchorElWkday);
  const handleWkdayPermPopoverOpen = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorElWkday(event.currentTarget);
  const handleWkdayPermPopoverClose = () => setAnchorElWkday(null);

  return (
    <>
      <Autocomplete
        id={`perm-select-${type}-${label}`}
        data-testid={`perm-select-${type}-${label}`}
        multiple
        disableCloseOnSelect
        disabled={disabledRemoteOpt}
        value={initialValue}
        options={entries ?? []}
        isOptionEqualToValue={(option, value) => checkOptEqual(label, option, value)}
        onChange={handleChange}
        renderInput={params => (
          <>
            <TextField
              {...params}
              variant="standard"
              label={t(generateLabel(label))}
              InputProps={{
                ...params.InputProps,
                endAdornment: <>{disabledRemoteOpt ? <CircularProgress size={15} /> : null}</>
              }}
            />
          </>
        )}
        getOptionLabel={option => checkOptLabel(label, option, t)}
        renderOption={(props, option) => (
          <li {...props} data-testid={`perm-select-opt${option.id}`} key={uuidv4()}>
            {checkOptLabel(label, option, t)}
          </li>
        )}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => {
            const { key, ...rest } = getTagProps({ index });
            return (
              <Chip
                key={key}
                {...rest}
                label={capitalize(checkOptLabel(label, option, t))}
                icon={
                  <>
                    <PermissionSelectSingleChip
                      option={option}
                      handleStrictPermPopoverOpen={handleStrictPermPopoverOpen}
                      handleStrictPermPopoverClose={handleStrictPermPopoverClose}
                      handleWkdayPermPopoverOpen={handleWkdayPermPopoverOpen}
                      handleWkdayPermPopoverClose={handleWkdayPermPopoverClose}
                      handleStricPermChange={handleStricPermChange}
                      setSelectOpt={setSelectOpt}
                    />
                  </>
                }
              />
            );
          })
        }
      />

      {/* tooltip popover for strict permission */}
      <Popover
        id="mouse-over-popover"
        sx={{ pointerEvents: "none" }}
        open={strictOpen}
        anchorEl={anchorElStrict}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        onClose={handleStrictPermPopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 1, fontSize: "0.8rem" }}>
          {t("StrictPermission_SelectOnChip")}
        </Typography>
      </Popover>

      {/* tooltip popover for weekday permissions */}
      <Popover
        id="mouse-over-popover"
        sx={{ pointerEvents: "none" }}
        open={wkdayOpen}
        anchorEl={anchorElWkday}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        onClose={handleWkdayPermPopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 1, fontSize: "0.8rem" }}>
          {t("Restrict this permission to certain weekdays")}
        </Typography>
      </Popover>

      {/* dialog for set weekday permission */}
      {selectOpt && (
        <WeekdayPermissionDialog
          option={selectOpt}
          initialPermWeekdays={selectOpt.activeWeekdays}
          handleActiveWkdayChange={handleActiveWkdayChange}
          setSelectOpt={setSelectOpt}
        />
      )}
    </>
  );
}
