import { useEffect, useMemo, useState } from "react";
import { Button, Grid, Popper, Typography, useMediaQuery, useTheme } from "@mui/material";
import {
  MRT_ColumnDef,
  MRT_TableOptions,
  MaterialReactTable,
  useMaterialReactTable
} from "material-react-table";
import { FileObject } from "react-mui-dropzone";
import { download, generateCsv, mkConfig } from "export-to-csv";
import { useTranslation } from "react-i18next";
import {
  EquipmentInventory,
  EquipmentStatus
} from "../../../features/FloorManager/typings/svg.types";
import { useRemoteCreateEquipment } from "../../../hooks/Remote/Devices/useRemoteCreateEquipment";
import { useRemoteDeleteEquipment } from "../../../hooks/Remote/Devices/useRemoteDeleteEquipment";
import { useRemoteFetchEquipmentInventory } from "../../../hooks/Remote/Devices/useRemoteFetchEquipmentInventory";
import { useRemoteUpdateEquipment } from "../../../hooks/Remote/Devices/useRemoteUpdateEquipment";
import {
  EquipmentCategories,
  EquipmentCategory,
  checkTargetValError,
  checkTargetValueInventoryId,
  handleExportRows,
  validateEquipmentRow
} from "../equipmentlist.functions";
import { FmDeviceDialog as BookingPlanDialog } from "./FmDeviceDialog/FmDeviceDialog";
import { mapNewRowToPartialEquipmentInventory } from "./helpers/mapNewRowToPartialEquipmentInventory.function";
import { mapUpdatedRowToPartialEquipmentInventory } from "./helpers/mapUpdatedRowToPartialEquipmentInventory.function";
import { FilterableEquipmentListTopToolbar } from "./Toolbar/FilterableEquipmentListTopToolbar";
import { FilterableEquipmentListRowActions } from "./filterable-equipment-list-row-actions";
import { EquipmentRow, EquipmentRowValues } from "./typings/equipment-row.types";
import { commonLocalization } from "../../../functions/tableLocalization";
import TableDeleteConfirmDialog from "../../Common/TableDeletionConfirmDialog/TableDeletionConfirmDialog.component";
import { useRemoteFetchAdminLocations } from "../../../hooks/Remote/Location/useRemoteFetchAdminLocations";
import { useRemoteFetchFloorByLocation } from "../../../hooks/Remote/Floor/useRemoteFetchFloorByLocation";
import { LocationInventory } from "../../../features/FloorManager/typings/location-inventory";
import { FloorInventory } from "../../../features/FloorManager/typings/floor-inventory.entity";
import {
  renderAssignedButton,
  renderSimpleCellName,
  SlidePanelCreateEdit
} from "./filterable-equipment-list.partial";

export const bookableArr = [
  // use translation for name later
  { name: "Yes", value: true },
  { name: "No", value: false }
];

/**
 * A tables that displays equipments and allows to update, delete and create new equipments.
 * It does not follow MVC-Patterns currently
 * todo refactor to follow MVC-Patterns
 * @param equipmentCategories
 */
export default function FilterableEquipmentList({ equipmentCategories }: EquipmentCategories) {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  const [data, setData] = useState<EquipmentRow[]>();
  const [open, setOpen] = useState<boolean>(false);

  const [selectedInventoryId, setSelectedInventoryId] = useState<number>();
  const [floorPlan, setFloorPlan] = useState<any>();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const exportPopOpen = Boolean(anchorEl);

  const [validationErrors, setValidationErrors] = useState<Record<string, string | undefined>>({});
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deletionSelected, setDeletionSelected] = useState<number[] | null>(null);
  const [imageFile] = useState<{ file: FileObject[] }>({ file: [] }); // setImageFile is not implemented yet

  /** get location and floor by the selected place/zone inventory id, then display as helper text below the location and floor */
  const [selectLocation, setSelectLocation] = useState<string | null>(null);
  const [, setSelectPlaceInventoryId] = useState<number | null>(null);
  const [, setSelectZoneInventoryId] = useState<number | null>(null);

  const {
    data: remoteEquipments,
    refetch: refetchRemoteEquipments,
    isLoading: isEquipmentsLoading,
    isFetching: isEquipmentsFetching
  } = useRemoteFetchEquipmentInventory();

  const { mutateAsync: removeEquipments, status: removeEquipmentStatus } =
    useRemoteDeleteEquipment();
  const { mutateAsync: addEquipments, status: addEquipmentStatus } = useRemoteCreateEquipment();
  const { mutateAsync: updateEquipments, status: updateEquipmentStatus } =
    useRemoteUpdateEquipment();

  const { data: locations } = useRemoteFetchAdminLocations({ mode: "admin" });
  const { data: floors, refetch: refetchFloors } = useRemoteFetchFloorByLocation(
    locations?.find(lc => lc.name === selectLocation)?.id
  );

  useEffect(() => {
    if (selectLocation && selectLocation !== t("No location")) refetchFloors();
  }, [selectLocation]);

  // export to csv config
  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
    filename: t("equipment")
  });

  // handle table to export as csv`
  const handleExportData = () => {
    if (!data) return;
    const csvData = data.map(d => ({
      id: d.id,
      name: d.name,
      locationName: d.locationName,
      floorName: d.floorName,
      costPerHour: d.costPerHour,
      assetRefId: d.assetRefId,
      equipmentCategoryId: d.equipmentCategory?.id,
      equipmentCategoryName: d.equipmentCategory?.name,
      equipmentCategoryDescription: d.equipmentCategory?.description,
      equipmentCategoryTenantId: d.equipmentCategory?.tenantId,
      placeInventoryId: d.placeInventoryId,
      zoneInventoryId: d.zoneInventoryId
      // status : d.status
      // bookable: d.bookable,
      // locationName: d.location.name,
      // floorName: d.floor.name,
    }));
    const csv = generateCsv(csvConfig)(csvData);
    download(csvConfig)(csv);
  };

  const column = useMemo<MRT_ColumnDef<EquipmentRow>[]>(
    () => [
      {
        accessorKey: "renderButton",
        header: "",
        enableColumnFilter: false,
        Cell: ({ row }) => renderAssignedButton(row, setSelectedInventoryId, setFloorPlan, setOpen),
        size: 10,
        enableEditing: false
      },
      // {
      //   accessorKey: "status",
      //   header: "", // t("Status"),
      //   enableEditing: true,
      //   size: 60,
      //   editVariant: "select",
      //   editSelectOptions: () =>
      //     Object.values(EquipmentStatus).map((stat, index) => ({
      //       label: t(stat),
      //       value: stat,
      //       key: `${stat}${index}`
      //     })),
      //   Cell: ({ row }) => renderAssignedButton(row, setSelectedInventoryId, setFloorPlan, setOpen),
      //   muiEditTextFieldProps: {
      //     required: true,
      //     error: !!validationErrors?.status,
      //     helperText: validationErrors?.status,
      //     onFocus: () =>
      //       /* istanbul ignore next */
      //       setValidationErrors({
      //         ...validationErrors,
      //         status: undefined
      //       }),
      //     onChange: e => {
      //       /* istanbul ignore next */
      //       checkTargetValError(e.target.value, "status", validationErrors, setValidationErrors);
      //     }
      //   }
      // },
      // {
      //   accessorKey: "imageUrl",
      //   header: t("Image"),
      //   enableEditing: true,
      //   enableColumnFilter: false,
      //   enableSorting: false,
      //   Cell: ({ cell }) => renderEquipmentImage(cell.row.original, 100),
      //   Edit: ({ column, row }) => (
      //     <EditEquipmentImage
      //       header={column.columnDef.header}
      //       rowOriginal={row.original}
      //       imageFile={imageFile}
      //       setImageFile={setImageFile}
      //     />
      //   ),
      //   size: 100
      // },
      {
        accessorKey: "name",
        header: t("Name"),
        size: 200,
        enableEditing: true,
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.name,
          helperText: validationErrors?.name,
          onFocus: () =>
            /* istanbul ignore next */
            setValidationErrors({
              ...validationErrors,
              name: undefined
            }),
          onChange: e => {
            checkTargetValError(
              e.currentTarget.value,
              "name",
              validationErrors,
              setValidationErrors
            );
          }
        }
      },
      {
        accessorKey: "description",
        header: t("Description"),
        size: 200,
        enableEditing: true,
        muiEditTextFieldProps: {
          required: false
        }
      },
      {
        header: t("Category"),
        accessorKey: "equipmentCategory.name",
        size: 150,
        editVariant: "select",
        editSelectOptions: () =>
          equipmentCategories.map(eqc => ({
            label: eqc.name,
            value: eqc.name,
            key: eqc.name
          }))
      },
      {
        header: t("Asset Id"),
        accessorKey: "assetRefId",
        size: 150,
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.assetRefId,
          helperText: validationErrors?.assetRefId,
          onFocus: () =>
            /* istanbul ignore next */
            setValidationErrors({
              ...validationErrors,
              assetRefId: undefined
            }),
          onChange: e => {
            checkTargetValError(
              e.currentTarget.value,
              "assetRefId",
              validationErrors,
              setValidationErrors
            );
          }
        }
      },
      {
        accessorKey: "location.name", // accessorKey: "locationName",
        header: t("Location"),
        size: 100,
        editVariant: "select",
        editSelectOptions: ({ row }) => {
          const locNull = { name: t("No location"), value: null };
          const locOpts = [locNull, ...(locations || [{ name: row.original.locationName }])];
          return locOpts.map(loc => ({ label: loc.name, value: loc.name, key: loc.name }));
        },
        muiEditTextFieldProps: {
          error: !!validationErrors?.location,
          helperText: validationErrors?.location,
          onChange: e => {
            /* istanbul ignore next */
            setSelectLocation(e.target.value);
            /* istanbul ignore next */
            checkTargetValError(e.target.value, "location", validationErrors, setValidationErrors);
          }
        },
        Cell: ({ row: { original } }) =>
          renderSimpleCellName(original.location?.name || original.locationName)
      },
      {
        header: t("Floor"),
        accessorKey: "floor.name", // accessorKey: "floorName",
        size: 100,
        editVariant: "select",
        editSelectOptions: ({ row }) => {
          const floorNull = { name: t("No floor"), value: null };
          const floorOpts = [floorNull, ...(floors || [{ name: row.original.floorName }])];
          return floorOpts.map(fl => ({ label: fl.name, value: fl.name, key: fl.name }));
        },
        muiEditTextFieldProps: ({ row }) => {
          return {
            error: !!validationErrors?.floor,
            helperText: validationErrors?.floor,
            onFocus: () => {
              /* istanbul ignore next */
              const origLoc = locations?.find(loc => loc.name === row.original.locationName)?.name;
              /* istanbul ignore next */
              if (!selectLocation && origLoc) setSelectLocation(origLoc);
            }
          };
        },
        Cell: ({ row: { original } }) => {
          return renderSimpleCellName(original.floor?.name || original.floorName);
        }
      },
      {
        header: t("Zone Id"),
        accessorKey: "zoneInventoryId",
        enableEditing: true,
        size: 100,
        muiEditTextFieldProps: {
          required: false,
          error: !!validationErrors?.zoneInventoryId,
          helperText: validationErrors?.zoneInventoryId,
          onFocus: () => {
            /* istanbul ignore next */
            setValidationErrors({
              ...validationErrors,
              zoneInventoryId: undefined
            });
          },
          onChange: e => {
            /* istanbul ignore next */
            checkTargetValueInventoryId(
              e.currentTarget.value,
              "zoneInventoryId",
              t("Zone Inventory Id must be number"),
              validationErrors,
              setValidationErrors,
              setSelectZoneInventoryId
            );
          }
        }
      },
      {
        header: t("Place Id"),
        accessorKey: "placeInventoryId",
        enableEditing: true,
        size: 100,
        muiEditTextFieldProps: {
          required: false,
          error: !!validationErrors?.placeInventoryId,
          helperText: validationErrors?.placeInventoryId,
          onFocus: () => {
            /* istanbul ignore next */
            setValidationErrors({
              ...validationErrors,
              placeInventoryId: undefined
            });
          },
          onChange: e => {
            /* istanbul ignore next */
            checkTargetValueInventoryId(
              e.currentTarget.value,
              "placeInventoryId",
              t("Place Inventory Id must be number"),
              validationErrors,
              setValidationErrors,
              setSelectPlaceInventoryId
            );
          }
        }
      },
      {
        header: t("Cost (h)"),
        accessorKey: "costPerHour",
        size: 30,
        muiEditTextFieldProps: {
          required: true,
          type: "number",
          error: !!validationErrors?.costPerHour,
          helperText: validationErrors?.costPerHour,
          onFocus: () =>
            /* istanbul ignore next */
            setValidationErrors({
              ...validationErrors,
              costPerHour: undefined
            }),
          onChange: e =>
            checkTargetValError(
              e.currentTarget.value,
              "costPerHour",
              validationErrors,
              setValidationErrors
            )
        }
      }
      // {
      //   header: t("Bookable"),
      //   accessorKey: "bookable",
      //   size: 30,
      //   enableEditing: true,
      //   editVariant: "select",
      //   editSelectOptions: () =>
      //     bookableArr.map(fl => ({ label: fl.name, value: fl.value, key: fl.value })),
      //   Cell: ({ row }) => renderCellBookable(row),
      //   muiEditTextFieldProps: {
      //     required: true,
      //     error: !!validationErrors?.bookable,
      //     helperText: validationErrors?.bookable,
      //     onFocus: () =>
      //       setValidationErrors({
      //         ...validationErrors,
      //         bookable: undefined
      //       }),
      //     onChange: e => {
      //       /* istanbul ignore next */
      //       checkTargetValError(e.target.value, "bookable", validationErrors, setValidationErrors);
      //     }
      //   }
      // }
    ],
    [
      validationErrors,
      remoteEquipments,
      equipmentCategories,
      i18n.language,
      locations,
      floors,
      imageFile
    ]
  );

  useEffect(() => {
    if (!remoteEquipments) return;

    setData(
      remoteEquipments.map(el => ({
        name: el.name,
        description: el.description,
        costPerHour: el.costPerHour,
        uid: el.uid,
        assetRefId: el.assetRefId,
        id: el.id,
        status: el.status ?? EquipmentStatus.AVAILABLE,
        locationName: el.locationName ?? "",
        floorName: el.floorName ?? "",
        equipmentCategory: el.equipmentCategory ?? { id: 0, name: "" },
        placeInventoryId: el.placeInventoryId,
        zoneInventoryId: el.zoneInventoryId,
        imageUrl: el.imageUrl ?? null,
        bookable: el.bookable ?? true,
        location:
          el.location ??
          ({
            name: el.locationName ?? "",
            id: 0
          } as any),
        floor: el.floor ?? ({ name: el.floorName ?? "", id: 0 } as any)
      }))
    );
  }, [remoteEquipments]);

  // after deleting a row, refresh the list
  useEffect(() => {
    if (removeEquipmentStatus === "success") {
      setIsDeleteModalOpen(false);
      refetchRemoteEquipments();
      setDeletionSelected(null);
    }
  }, [removeEquipmentStatus]);

  // after adding a eow, refresh the list
  useEffect(() => {
    if (addEquipmentStatus === "success") refetchRemoteEquipments();
  }, [addEquipmentStatus]);

  // after adding a eow, refresh the list
  useEffect(() => {
    if (updateEquipmentStatus === "success") refetchRemoteEquipments();
  }, [updateEquipmentStatus]);

  const resetTableCreateEdit = () => {
    setValidationErrors({});
    setSelectLocation(null);
  };

  const updateEquipmentCategoryValue = (
    equipmentCategories: EquipmentCategory[],
    values: EquipmentRowValues
  ) => {
    const upd = values;
    const selectedEquipCate = equipmentCategories.find(
      c => upd["equipmentCategory.name"] === c.name
    );

    upd["equipmentCategory.id"] = selectedEquipCate?.id;
    upd["equipmentCategory.description"] = selectedEquipCate?.description;
    upd["equipmentCategory.tenantId"] = selectedEquipCate?.tenantId;

    return upd;
  };

  const updateEquipmentLocationFloorValue = (
    values: EquipmentRowValues,
    locations: LocationInventory[] | undefined,
    floors: FloorInventory[] | undefined
  ) => {
    const upd = values;
    const selectedLoc = locations?.find(loc => values["location.name"] === loc.name);
    const selectedFlo = floors?.find(flo => values["floor.name"] === flo.name);

    upd["location"] = selectedLoc;
    upd["locationName"] = selectedLoc?.name;
    upd["floor"] = selectedFlo;
    upd["floorName"] = selectedFlo?.name;

    // sanity check when floor isn't refeched but value is existed, keep the original value
    if (!selectedFlo && values["floor.name"]) upd["floorName"] = values["floor.name"];

    return upd;
  };

  /** deletes equipments */
  async function handleRowDelete(rowUids: number[]) {
    removeEquipments({ ids: [...rowUids] });
    table.resetRowSelection();
  }

  /** adds an equipment */
  const handleRowAdd: MRT_TableOptions<EquipmentRow>["onCreatingRowSave"] = async ({
    values,
    exitCreatingMode
  }) => {
    const newValidationErrors = validateEquipmentRow(values);

    if (Object.values(newValidationErrors).some(error => error)) {
      return setValidationErrors(newValidationErrors);
    }

    resetTableCreateEdit();

    const updated1 = updateEquipmentCategoryValue(equipmentCategories, values);
    const updated2 = updateEquipmentLocationFloorValue(updated1, locations, floors);

    const newVal = mapNewRowToPartialEquipmentInventory(updated2);

    exitCreatingMode();
    await addEquipments(newVal);
    table.setCreatingRow(null); //exit creating mode
  };

  /** updates an equipment */
  const handleRowUpdate: MRT_TableOptions<EquipmentRow>["onEditingRowSave"] = async ({
    values,
    exitEditingMode
  }) => {
    const newValidationErrors = validateEquipmentRow(values);
    if (Object.values(newValidationErrors).some(error => error)) {
      return setValidationErrors(newValidationErrors);
    }

    resetTableCreateEdit();

    const updated1 = updateEquipmentCategoryValue(equipmentCategories, values);
    const updated2 = updateEquipmentLocationFloorValue(updated1, locations, floors);

    const newVal = mapUpdatedRowToPartialEquipmentInventory(updated2);

    exitEditingMode();
    await updateEquipments(newVal as EquipmentInventory);
    table.setEditingRow(null); // exit editing mode
  };

  const table = useMaterialReactTable({
    columns: column ?? [],
    data: data ?? [],
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableHiding: true,
    enableRowSelection: true,
    createDisplayMode: "modal",
    editDisplayMode: "modal",
    enableEditing: true,
    getRowId: row => row.id?.toString(),
    onCreatingRowCancel: resetTableCreateEdit,
    onCreatingRowSave: handleRowAdd,
    onEditingRowCancel: resetTableCreateEdit,
    onEditingRowSave: handleRowUpdate,
    state: {
      isLoading: isEquipmentsLoading,
      isSaving:
        (addEquipmentStatus || updateEquipmentStatus || removeEquipmentStatus) === "loading",
      showProgressBars: isEquipmentsFetching
    },
    initialState: {
      showColumnFilters: true,
      showGlobalFilter: true,
      pagination: { pageSize: 10, pageIndex: 0 }
    },
    positionToolbarAlertBanner: "none",
    muiSearchTextFieldProps: {
      size: "small",
      variant: "outlined"
    },
    muiPaginationProps: {
      rowsPerPageOptions: [5, 10, 15, 20, 30],
      variant: "outlined"
    },
    paginationDisplayMode: "pages",
    localization: {
      ...commonLocalization(t),
      noRecordsToDisplay: t("There are no equipments")
    },
    renderRowActions: ({ row, table }) => {
      return (
        <FilterableEquipmentListRowActions
          row={row}
          table={table}
          setIsDeleteModalOpen={setIsDeleteModalOpen}
          setDeletionSelected={setDeletionSelected}
        />
      );
    },
    renderTopToolbar: ({ table }) => {
      return (
        <FilterableEquipmentListTopToolbar
          table={table}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          exportPopOpen={exportPopOpen}
          onReload={async () => {
            await refetchRemoteEquipments();
          }}
          disabledDeleteButton={table.getSelectedRowModel().flatRows.length <= 0}
          onDelete={() => {
            setIsDeleteModalOpen(true);

            const selected = table.getSelectedRowModel().rows.map(row => row.original.id);
            setDeletionSelected(selected);
          }}
        />
      );
    },
    renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
      <SlidePanelCreateEdit
        table={table}
        row={row}
        internalEditComponents={internalEditComponents}
        headline={t("Add Equipment")}
      />
    ),
    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
      <SlidePanelCreateEdit
        table={table}
        row={row}
        internalEditComponents={internalEditComponents}
        headline={t("Edit Equipment")}
      />
    )
  });

  return (
    <>
      <MaterialReactTable table={table} />

      {/* Confirm Deletion Modal */}
      <TableDeleteConfirmDialog
        dialogDesc={
          deletionSelected?.length === 1
            ? t("_tableSingleDeletionConfirmationDesc", { title: t("Equipment") })
            : t("_tableDeletionConfirmationDesc")
        }
        isDeleteModalOpen={isDeleteModalOpen}
        setIsDeleteModalOpen={setIsDeleteModalOpen}
        onConfirm={() => {
          /* istanbul ignore next */
          if (deletionSelected?.length) handleRowDelete(deletionSelected);
        }}
        deleteStatus={removeEquipmentStatus}
      />

      {/* export table button selecting csv or pdf */}
      <Popper open={exportPopOpen} anchorEl={anchorEl} nonce={undefined}>
        <Grid
          sx={{
            width: "min-content",
            display: "flex",
            flexDirection: "column",
            p: 1,
            bgcolor: theme.palette.background.paper // bgcolor: "background.paper"
          }}
        >
          <Grid item>
            <Button
              data-testid="export-btn-csv"
              onClick={handleExportData}
              sx={{ minWidth: "8rem", mt: 0.5, mb: 0.5 }}
            >
              <Typography>{t("Export as CSV")}</Typography>
            </Button>
          </Grid>
          <Grid item>
            <Button
              data-testid="export-btn-pdf"
              onClick={() => handleExportRows(table.getPrePaginationRowModel().rows, t)}
              sx={{ minWidth: "8rem", mt: 0.5, mb: 0.5 }}
            >
              <Typography>{t("Export as PDF")}</Typography>
            </Button>
          </Grid>
        </Grid>
      </Popper>

      <BookingPlanDialog
        open={open}
        /* istanbul ignore next */
        handleClose={() => setOpen(false)}
        isMobile={isMobile}
        floorPlan={floorPlan}
        selectedInventoryId={selectedInventoryId}
      />
    </>
  );
}
