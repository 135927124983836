import { useEffect, useState } from "react";
import { Box, Button, Dialog, DialogContent, FormGroup, Stack, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { PictureDropzone } from "../../../../Pickers/picture-dropzone.component";
import { useRefHandler } from "../../../../../features/FloorManager/partials/viewport-scaler-modal.components";
import { IViewport } from "../../../Domain/Types/FloorPlan/Viewport.type";
import { FileObject } from "react-mui-dropzone";
import { IFloorPayload } from "../../../Domain/Types/FloorPlan/FloorPayload.type";
import { useSelector } from "../../../../../app/helpers";
import FormDialog from "../../../../../components/Dialog/form-dialog.component";
import { ChangeFloorFrameSlider } from "./ChangeFloorFrameSliderComponent";
import selectedEntitiesSelector from "../../../../../features/FloorManager/selectors/selectedEntitiesSelector";

interface Props {
  isChangeFrameModalOpen: boolean;
  setIsChangeFrameModalOpen: (o: boolean) => void;
  floorPlan: IFloorPayload;
  updateFloorFrame: (newFrame: FileObject, newViewport: IViewport) => void;
}

export function ChangeFloorFrameModal({
  isChangeFrameModalOpen,
  setIsChangeFrameModalOpen,
  floorPlan,
  updateFloorFrame
}: Props) {
  const { t } = useTranslation();
  const selected = useSelector(selectedEntitiesSelector);

  /** to open floor frame change the floor plan */
  const [viewportScalerOpen, setViewportScalerOpen] = useState(false);
  const [values, setValues] = useState<{ file: FileObject[]; viewport: IViewport }>({
    file: [],
    viewport: { width: 0, height: 0 }
  });
  const [modifiedViewportRef, handleChange] = useRefHandler(values.viewport);

  const resetViewport = () => {
    setIsChangeFrameModalOpen(false);
  };

  /* istanbul ignore next */
  const handleFinish = () => {
    if (!values.file.length || !selected.floor?.uid) return;
    setValues(prev => ({ ...prev, viewport: modifiedViewportRef.current }));

    setViewportScalerOpen(false);
    setIsChangeFrameModalOpen(false);
    updateFloorFrame(values.file[0], modifiedViewportRef.current);
  };

  useEffect(() => {
    setValues({ file: [], viewport: floorPlan.viewport });
  }, []);

  return (
    <Dialog open={isChangeFrameModalOpen} maxWidth={"md"} data-testid="change-floorFrame-dialog">
      <DialogContent data-testid="change-floorFrame-dialog-content">
        <Stack gap={2} data-testid="change-floorFrame-stack">
          <PictureDropzone
            title={t("Upload an image of the location")}
            files={values.file}
            error={!values.file}
            onAdd={files => setValues(prev => ({ ...prev, file: files }))}
            onDelete={() => setValues(prev => ({ ...prev, file: [] }))}
            current={floorPlan.outlineUrl}
          />

          <FormGroup row>
            <TextField
              type="number"
              id="viewportX"
              label={t("Width")}
              value={values.viewport.width}
              disabled
            />
            <TextField
              type="number"
              id="viewportY"
              label={t("Height")}
              value={values.viewport.height}
              disabled
            />
            <Button
              color="primary"
              sx={{ margin: "0 10px 0 10px" }}
              onClick={() => setViewportScalerOpen(true)}
              disabled={!values.file.length}
              data-testid="adjust-floorFrame-btn"
            >
              {t("Adjust Floor Frame")}
            </Button>
            <Button color="secondary" onClick={resetViewport} data-testid="adjust-cancel-btn">
              {t("Cancel")}
            </Button>
          </FormGroup>
          {values.file && values.file[0] && values.viewport.width !== 0 && (
            <FormDialog
              isOpen={viewportScalerOpen}
              title={t("Adjust Floor Frame")}
              handleCancel={() => setViewportScalerOpen(false)}
              handleOk={handleFinish}
              maxWidth={"lg"}
            >
              <Box sx={{ maxHeight: "60vh" }}>
                <div id="floor-frame-parent" style={{ width: "100%", height: "60vh" }}>
                  <ChangeFloorFrameSlider
                    imageUrl={values.file[0].data as string}
                    initialViewport={values.viewport}
                    floorPlan={floorPlan}
                    onChange={handleChange}
                  />
                </div>
              </Box>
            </FormDialog>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
