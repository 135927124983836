import { PropsWithChildren, useEffect, useState } from "react";
import { Point, Texture, Resource } from "pixi.js";
import { Sprite } from "@pixi/react";
import { useSelector } from "../../../../../app/helpers";
import { RootState } from "../../../../../app/rootReducer";
import { DisplayViewport } from "../../DisplayViewport/DisplayViewport";
import { ZoneContainer } from "../../Zone/ZoneContainer";
import { PlaceContainer } from "../../Place/PlaceContainer";
import { IFloorPayload } from "../../../Domain/Types/FloorPlan/FloorPayload.type";
import { ISeatStatus } from "../../../Domain/Types/FloorPlan/SeatStatus.type";
import { generatePlaceVariant } from "../../../Functions/generateVariant";
import { CapacityObject } from "../../../../../features/Reports/typings/reports.types";
import { useSelection } from "../../../Hooks/useSelection/useSelection";
import { useGeometry } from "../../../Hooks/useGeometry";
import { IViewport } from "../../../Domain/Types/FloorPlan/Viewport.type";
import { BookingType } from "../../../../../features/Booking-Form/typings/booking-inputs";
import { IPlaceSchedule } from "../../../Domain/Types/FloorPlan/PlaceSchedule";
import {
  generateColorOverlay,
  renderReportPlaceBySelected,
  renderReportZoneBySelected
} from "./ReportFloorPlanView.functions";
import { PlanViewMode } from "../../PixiViewport/PixiViewport";

interface Props {
  width: number;
  height: number;
  name: string;
  floorPlan: IFloorPayload;
  seatStatus: ISeatStatus;
  background: Texture<Resource>;
  reportData: (CapacityObject | undefined)[] | undefined;
  /** show if chip is on in the frontend */
  isSelectedChip?: boolean;
  onClickReportPlace?: (inventoryIds: number[], names: string[], placeTypes: BookingType[]) => void;
  onClickReportZone?: (
    inventoryIds: number[],
    names: string[],
    zoneTypes: BookingType[],
    highlightedPlaces: IPlaceSchedule[]
  ) => void;
}

export function ReportFloorPlanView({
  children,
  name,
  background,
  floorPlan,
  seatStatus,
  reportData,
  isSelectedChip,
  onClickReportPlace,
  onClickReportZone
}: PropsWithChildren<Props>) {
  const { selectedZoneOrWorkplace } = useSelector((state: RootState) => state.report);

  const {
    selectZone,
    selectDesk,
    wipeSelection,
    wipeDeskSelection,
    wipeZoneSelection,
    selectedDesks,
    selectedZones
  } = useSelection();
  const { convertPixiPoints } = useGeometry();

  const [isSamePlaceState, setIsSamePlaceState] = useState<number[]>([]);
  const [isSameZoneState, setIsSameZoneState] = useState<number[]>([]);

  const [viewportDimensions, setViewportDimensions] = useState<IViewport>({ width: 0, height: 0 });
  const [placeScale, setPlaceScale] = useState(0);

  const highlightedPlace = floorPlan.places.filter(workplace =>
    selectedZones.some(zn => workplace.zoneScheduleId === zn)
  );

  /* istanbul ignore next */
  function onClickZone(zoneId: number) {
    const selected = floorPlan.zones.find(zone => zone.id === zoneId);
    const alreadySelected = floorPlan.zones.filter(
      zone => selectedZones.length && selectedZones.includes(zone.id)
    );
    const selectedType = selected?.inventory?.zoneTypeId || selected?.inventory?.zoneType?.id;
    const alreadySelectedType = alreadySelected.map(
      s => s.inventory?.zoneTypeId || s.inventory?.zoneType?.id
    )[0];

    // during the multi selection if the type is different from the previous select, re-start multi select
    if (selectedType !== alreadySelectedType) wipeZoneSelection();

    selectZone(zoneId);
  }

  /* istanbul ignore next */
  function onClickPlace(deskId: number) {
    const selected = floorPlan.places.find(place => place.id === deskId);
    const alreadySelected = floorPlan.places.filter(
      place => selectedDesks.length && selectedDesks.includes(place.id)
    );
    const selectedType = selected?.inventory?.placeTypeId || selected?.inventory?.placeType?.id;
    const alreadySelectedType = alreadySelected.map(
      s => s.inventory?.placeTypeId || s.inventory?.placeType?.id
    )[0];

    // during the multi selection if the type is different from the previous select, re-start multi select
    if (selectedType !== alreadySelectedType) wipeDeskSelection();

    selectDesk(deskId);
  }

  useEffect(() => {
    renderReportPlaceBySelected(floorPlan, selectedDesks, onClickReportPlace);
    renderReportZoneBySelected(floorPlan, selectedZones, highlightedPlace, onClickReportZone);
  }, [selectedDesks, selectedZones]);

  // set use-selection hooks only when the selected state is aligned with state
  // to avoid useSelection works at both floors when comparing two floors
  useEffect(() => {
    if (selectedZoneOrWorkplace && selectedZoneOrWorkplace.open) {
      const selectedStateWps = floorPlan.places.filter(w =>
        selectedZoneOrWorkplace.id.includes(w.inventory?.id || 0)
      );
      const selectedStateZn = floorPlan.zones.filter(z =>
        selectedZoneOrWorkplace.id.includes(z.inventory?.id || 0)
      );

      if (selectedStateWps.length) setIsSamePlaceState(selectedStateWps.map(w => w.id));
      if (selectedStateZn.length) setIsSameZoneState(selectedStateZn.map(z => z.id));

      if (!selectedStateWps.length) setIsSamePlaceState([]);
      if (!selectedStateZn.length) setIsSameZoneState([]);
    }
  }, [selectedZoneOrWorkplace, floorPlan]);

  useEffect(() => {
    if (!background) return;
    setPlaceScale(background.width / floorPlan.viewport.width);
  }, [background]);

  useEffect(() => {
    if (!isSelectedChip) {
      wipeSelection();
      setIsSamePlaceState([]);
      setIsSameZoneState([]);
    }
  }, [isSelectedChip]);

  return (
    <DisplayViewport
      planViewMode={PlanViewMode.REPORT}
      floorPlanName={name}
      initialZoomEnd={new Point(background.width, background.height)}
      currentFloorPlan={undefined}
      placeScale={placeScale}
      viewportDimensions={viewportDimensions}
      setViewportDimensions={setViewportDimensions}
    >
      <Sprite texture={background} />

      {/** draw zones */}
      {floorPlan.zones.map(zone => (
        <ZoneContainer
          isClickable
          key={zone.id}
          id={zone.id}
          inventoryId={zone.inventoryId || zone.inventory?.id}
          onClick={onClickZone}
          walls={convertPixiPoints(zone.coordinates)}
          disabled={zone.disabled}
          zoneType={zone.zoneTypeId || zone.inventory?.zoneTypeId || 1}
          colorOverlay={generateColorOverlay({
            type: "zone",
            inventoryId: zone.inventoryId || zone.inventory?.id,
            reportData,
            floorPlan
          })}
          isSelected={isSameZoneState.includes(zone.id)}
        />
      ))}

      {/** draw places */}
      {!!placeScale &&
        floorPlan.places.map(workplace => (
          <PlaceContainer
            isSelectable
            key={workplace.id}
            boundingBox={{
              width: workplace.inventory?.boundingBox?.width ?? 160,
              height: workplace.inventory?.boundingBox?.height ?? 80
            }}
            id={workplace.id}
            inventoryId={workplace.inventoryId || workplace.inventory?.id}
            rotation={workplace.rotate}
            position={workplace.position}
            variant={generatePlaceVariant(
              { workplace: workplace, zone: undefined },
              seatStatus,
              workplace.disabled
            )}
            colorOverlay={generateColorOverlay({
              type: "place",
              inventoryId: workplace.inventoryId || workplace.inventory?.id,
              reportData,
              floorPlan
            })}
            onClick={onClickPlace}
            placeTypeId={workplace.placeTypeId || workplace.inventory?.placeTypeId}
            placeScale={placeScale}
            isHighlighted={highlightedPlace.map(h => h.id).includes(workplace.id)}
            isSelected={isSamePlaceState.includes(workplace.id)}
          />
        ))}
      {children}
    </DisplayViewport>
  );
}
