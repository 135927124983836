import { Chip, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "../../app/helpers";
import {
  SelectedZoneOrWorkplaceType,
  setIsSelected,
  setSelectedBookingType,
  setSelectedZoneOrWorkplace
} from "../../features/Reports/slices/report.slice";
import { fetchReport } from "../../features/Reports/thunks/report.thunk";
import DefaultSpinner from "../LoadingSpinner/default-spinner.component";
import { RootState } from "../../app/rootReducer";
import { IFloorPayload } from "../FacilityManager/Domain/Types/FloorPlan/FloorPayload.type";
import { CapacityRate } from "../FacilityManager/Domain/Types/CapacityRates.type";
import { ReportFloorPlanView } from "../FacilityManager/Components/Views/ReportFloorPlanView/ReportFloorPlanView";
import { useBackgroundImage } from "../FacilityManager/Hooks/useBackgroundImage";
import { BookingType } from "../../features/Booking-Form/typings/booking-inputs";
import { SvgReportHeaderChips } from "./SvgReportHeaderChip/SvgReportHeaderChip.component";
import {
  CurrentFloorInfo,
  calDailyValue,
  calFloorBookingObj,
  calSharingIndex,
  generateAverageUser
} from "./functions/svg-dialog.functions";
import { IPlaceSchedule } from "../FacilityManager/Domain/Types/FloorPlan/PlaceSchedule";
import { findMostPlaceType } from "../FacilityManager/Components/Views/ReportFloorPlanView/ReportFloorPlanView.functions";

type P = {
  /** Contains information about floor plan, such as desks, zones, etc. */
  floorData: IFloorPayload;
  /** URL to floor outline image. */
  backgroundUrl?: string;

  /** Map of workplace ID to their utilization Rate. */
  utilizationRates: CapacityRate[];

  zoneBooking: boolean;

  /** Custom height prop */
  height?: number;

  /** Custom name prop */
  name?: string;

  currentFloorInfo?: CurrentFloorInfo;
};
/**
 * Renders a floor plan report.
 * @description Desks recieve a fill color according to their usage rate.
 * @param props
 */
const SvgReport: React.FC<P> = ({
  floorData,
  utilizationRates,
  height,
  name = "floor",
  zoneBooking,
  currentFloorInfo
}) => {
  const { t } = useTranslation();
  const chipSxStyle = { position: "relative", top: "-490px", fontSize: "15px" };

  const dispatch = useDispatch();
  const {
    selectedZoneOrWorkplace,
    selectedTimeframe,
    calculateWithoutCosts,
    capacitySelectedWorkplaceOrZone,
    currentFloors,
    zoneRestrictions,
    capacitySelectedFloors,
    isSelected
  } = useSelector((state: RootState) => state.report);

  const {
    userInformation: { sub }
  } = useSelector((state: RootState) => state.login);

  const { background } = useBackgroundImage({
    backgroundImageUrl: floorData.outlineUrl,
    viewport: floorData.viewport
  });

  const currentEntityData = selectedZoneOrWorkplace?.open
    ? [capacitySelectedWorkplaceOrZone]
    : capacitySelectedFloors?.filter(floor => {
        return currentFloors.some(activeFloor => activeFloor.id === floor.id);
      });

  const averageUsers = generateAverageUser(
    currentEntityData,
    selectedZoneOrWorkplace,
    capacitySelectedFloors,
    currentFloorInfo
  );

  const currentFloorCapacity = currentEntityData?.find(d => d?.id === currentFloorInfo?.floor.id);
  const currentFloorCapaUniqueIds =
    currentFloorCapacity?.capacity.uniqueInventoryIds ||
    capacitySelectedWorkplaceOrZone?.capacity.uniqueInventoryIds;

  const { numWorkplaces, numParkings, numElectricchargings, numConferences } = calFloorBookingObj(
    floorData,
    selectedZoneOrWorkplace,
    currentFloorCapaUniqueIds
  );

  const { dev, avg } = calDailyValue(
    currentFloorCapacity,
    selectedZoneOrWorkplace,
    capacitySelectedWorkplaceOrZone,
    currentFloorInfo
  );

  const basicReportParams = {
    cost: calculateWithoutCosts,
    sub: sub,
    zoneBooking: zoneBooking,
    companyId: zoneRestrictions?.company?.id,
    zoneBookingObject: zoneRestrictions?.object?.zoneBookingObject,
    status: true,
    update: false
  };

  const onPlaceClick = (inventoryIds: number[], names: string[], placeTypes: BookingType[]) => {
    if (selectedTimeframe) {
      dispatch(setIsSelected(true));
      dispatch(setSelectedBookingType(placeTypes[0]));
      dispatch(
        fetchReport({
          ...basicReportParams,
          id: inventoryIds,
          filterType: "workplace",
          bookingType: placeTypes,
          start: selectedTimeframe.timeframeStart,
          end: selectedTimeframe.timeframeEnd
        })
      ).then(() => {
        dispatch(
          setSelectedZoneOrWorkplace({
            id: inventoryIds,
            type: SelectedZoneOrWorkplaceType.WORKPLACE,
            name: names,
            open: true,
            floorInventoryId: floorData.floorInventoryId,
            bookingType: placeTypes[0]
          })
        );
      });
    }
  };

  const onZoneClick = (
    inventoryIds: number[],
    names: string[],
    zoneTypes: BookingType[],
    highlightedPlaces: IPlaceSchedule[]
  ) => {
    if (selectedTimeframe) {
      const mostPlaceType = findMostPlaceType(highlightedPlaces);
      const znType = zoneTypes[0] === BookingType.CONFERENCEZONE ? zoneTypes[0] : mostPlaceType;

      dispatch(setIsSelected(true));
      dispatch(setSelectedBookingType(znType));
      dispatch(
        fetchReport({
          ...basicReportParams,
          id: inventoryIds,
          filterType: "zone",
          bookingType: znType,
          start: selectedTimeframe.timeframeStart,
          end: selectedTimeframe.timeframeEnd
        })
      ).then(() => {
        dispatch(
          setSelectedZoneOrWorkplace({
            id: inventoryIds,
            type: SelectedZoneOrWorkplaceType.ZONE,
            name: names,
            open: true,
            floorInventoryId: floorData.floorInventoryId,
            bookingType: znType
          })
        );
      });
    }
  };

  if (!utilizationRates || !background) return <DefaultSpinner />;
  else
    return (
      <div style={{ height }}>
        <ReportFloorPlanView
          background={background}
          width={800}
          height={height ?? 600}
          name={name}
          floorPlan={floorData as IFloorPayload}
          seatStatus={{ availableList: [], occupiedList: [], disableList: [], restrictedList: [] }}
          reportData={currentEntityData}
          onClickReportPlace={(inventoryIds, names, placeTypes) =>
            onPlaceClick(inventoryIds, names, placeTypes)
          }
          onClickReportZone={(inventoryIds, names, zoneTypes, highlightedPlaces) =>
            onZoneClick(inventoryIds, names, zoneTypes, highlightedPlaces)
          }
          isSelectedChip={isSelected}
        />
        {name && averageUsers && (
          <>
            {/* floor name */}
            <Chip
              label={name}
              size={"medium"}
              sx={{ ...chipSxStyle, left: "10px", marginRight: 1.3 }}
              color={"primary"}
            />

            {/* distinct Total Users */}
            <SvgReportHeaderChips
              label={averageUsers.length || 0}
              tooltip={t("Average users visited")}
              icon={"users"}
            />

            {/* booking objects - workplaces */}
            {!!numWorkplaces && (
              <SvgReportHeaderChips
                label={numWorkplaces}
                tooltip={t("Workplace")}
                icon={BookingType.WORKPLACE}
              />
            )}

            {/* booking objects - parking places */}
            {!!numParkings && (
              <SvgReportHeaderChips
                label={numParkings}
                tooltip={t("Parking")}
                icon={BookingType.PARKINGPLACE}
              />
            )}

            {/* booking objects - electric charging places */}
            {!!numElectricchargings && (
              <SvgReportHeaderChips
                label={numElectricchargings}
                tooltip={t("Electric Charging")}
                icon={BookingType.ELECTRICCHARGINGSTATIONPLACE}
              />
            )}

            {/* booking objects - conference zones */}
            {!!numConferences && (
              <SvgReportHeaderChips
                label={numConferences}
                tooltip={t("Conference")}
                icon={BookingType.CONFERENCEZONE}
              />
            )}

            {/* day average */}
            <Tooltip title={t("Day average")}>
              <Chip
                data-testid={"day-average-chip"}
                label={avg}
                size={"medium"}
                sx={{ ...chipSxStyle, marginLeft: 0.1, marginRight: 0.1 }}
                color={"primary"}
              />
            </Tooltip>

            {/* day standard deviation */}
            <Tooltip title={t("Day standard deviation")}>
              <Chip
                data-testid={"day-standard-deviation"}
                label={dev}
                size={"medium"}
                sx={{ ...chipSxStyle, marginLeft: 0.1, marginRight: 0.1 }}
                color={"primary"}
              />
            </Tooltip>

            {/* sharing index = #bookedUsers/#bookingObjects */}
            <Tooltip title={t("Sharing Index")}>
              <Chip
                data-testid={"Sharing-Index"}
                label={calSharingIndex(averageUsers, currentFloorCapaUniqueIds)}
                size={"medium"}
                sx={{ ...chipSxStyle, marginLeft: 0.1, marginRight: 0.1 }}
                color={"primary"}
              />
            </Tooltip>
          </>
        )}
      </div>
    );
};

export default SvgReport;
