import Axios, { AxiosResponse } from "axios";
import { FileObject } from "react-mui-dropzone";
import bookingBackend, { getOrgBackend } from "../app/backend";
import { Weekdays } from "../components/Pickers/WeekdayPicker/weekday-picker.component";
import { WeekdaySelection } from "../components/Settings/automated-desk-booking.component";
import { UserOrEmailMapping } from "../features/Admin/typings/admin.types";
import {
  GetBookingFloorParams,
  GetBookingLocationParams
} from "../features/Booking-Form/thunks/booking.thunks";
import { BookingType } from "../features/Booking-Form/typings/booking-inputs";
import {
  Booking,
  BookingScheduleDto,
  BookingScheduleInterface,
  CheckZoneBookings,
  ConferenceBookingDto,
  PlaceBookingDto,
  PlaceZoneBookingDto
} from "../features/Booking-Form/typings/booking.types";
import { FilterDto } from "../features/Booking-Form/typings/inputs-filter";
import {
  CapacityManagementAssistentInfoDto,
  EntityType
} from "../features/Capacity/capacity-assistant.component";
import { CheckinDto, CheckinResponseDto } from "../features/Checkin/typings";
import { Colleague } from "../features/Connections/types/Colleague.type";
import { FloorInventory } from "../features/FloorManager/typings/floor-inventory.entity";
import { FloorPayload } from "../features/FloorManager/typings/floor-plan";
import { LocationInventory } from "../features/FloorManager/typings/location-inventory";
import { Stakeholder } from "../features/FloorManager/typings/permission.entity";
import { DisabledSchedule } from "../features/FloorManager/typings/shared/timeframe";
import { PlaceScheduleEquipment } from "../features/FloorManager/typings/svg.types";
import { ZoneCategory } from "../features/FloorManager/typings/zone-category";
import { UserRole } from "../features/Login/typings/auth.types";
import { OccupationReport } from "../features/Reports/typings/occupation-report";
import {
  CapacityPerformance,
  getCapacityManagerEntitiesDto,
  getCapacityManagerOrFinanceTimeframeDto,
  getCapacityMangerOrZoneBookingReportDto,
  getFinanceReportDto,
  getZoneBookingTimeframeDto
} from "../features/Reports/typings/reports.types";
import {
  InactivateBookingsDto,
  InactivateBookingsResponse,
  UpdateBookingTimes,
  UpdateConferenceAttributes
} from "../features/Schedule/schedule.types";
import { AppSettings } from "../features/Settings/typings/user-settings.types";
import { IZoneCategory } from "../components/FacilityManager/Domain/Types/FloorPlan/ZoneCategory.type";
import { IPlaceCategory } from "../components/FacilityManager/Domain/Types/FloorPlan/PlaceCategory.type";
import { BookingApproval } from "../features/Approval/typings/booking-approval-type";
import { IFloorPayload } from "../components/FacilityManager/Domain/Types/FloorPlan/FloorPayload.type";
import { ICategoryCrud } from "../components/FacilityManager/Domain/Types/FloorPlan/ScheduleCategory.type";

/**
 * Make FormData from params
 */
export function serializeLocationInventory(
  {
    uid,
    timezone,
    address1,
    address2,
    address3,
    postalCode,
    city,
    state,
    country,
    allowChangeDeskCost,
    allowForBooking,
    schedule,
    bookingAllowedAt,
    color,
    permissions,
    name,
    maxLevels,
    isMobileWorking,
    checkinPeriod,
    checkinRadius,
    accessWithGeoLocation = false
  }: LocationInventory,
  picture?: FileObject[]
): FormData {
  const data = new FormData();
  data.append("uid", uid);
  data.append("name", name);

  data.append("address1", address1);
  if (address2) {
    data.append("address2", address2);
  }
  if (address3) {
    data.append("address3", address3);
  }
  if (postalCode) {
    data.append("postalCode", postalCode);
  }
  if (city) {
    data.append("city", city);
  }
  if (state) {
    data.append("state", state);
  }
  data.append("country", country);
  data.append("timezone", timezone);
  permissions &&
    permissions.length !== 0 &&
    data.append("permissions", JSON.stringify(permissions));
  data.append("allowChangeDeskCost", allowChangeDeskCost ? "true" : "");
  data.append("allowForBooking", allowForBooking ? "true" : "");
  data.append("maxLevels", String(maxLevels));
  data.append("checkinPeriod", String(checkinPeriod));
  data.append("checkinRadius", String(checkinRadius));
  data.append("color", color);
  data.append("isMobileWorking", isMobileWorking ? "true" : "");
  bookingAllowedAt && data.append("bookingAllowedAt", bookingAllowedAt);
  picture && data.append("file", picture[0].file);
  if (schedule && schedule[0]) {
    const {
      companyId,
      description,
      budget,
      defaultDeskCost,
      incentiveFactor,
      periodLength,
      vacancyFactor,
      workingHoursPerMonth
    } = schedule[0];

    data.append("description", description);
    data.append("companyId", String(companyId));
    data.append("vacancyFactor", String(vacancyFactor));
    data.append("incentiveFactor", String(incentiveFactor));
    data.append("budget", String(budget));
    data.append("periodLength", String(periodLength));
    data.append("workingHoursPerMonth", String(workingHoursPerMonth));
    data.append("defaultDeskCost", String(defaultDeskCost));
  }
  data.append("accessWithGeoLocation", String(accessWithGeoLocation));

  return data;
}

/** @deprecated use useRemoteCreateLocation instead, move logic to component when migrating! */
export const newLocation = (obj: LocationInventory, picture?: FileObject[]) => {
  if (!obj.schedule || !obj.schedule[0])
    throw new Error("Expected new location to have one schedule");

  const data = serializeLocationInventory(obj, picture);

  return Axios.post("/location", data);
};

/** @deprecated use useRemoteCreateFloor instead, move logic to component when migrating */
export const newFloor = (floor: FloorInventory, picture: File) => {
  const data = new FormData();
  data.append("name", floor.name);
  data.append("uid", floor.uid);
  data.append("locationInventoryId", String(floor.locationInventoryId));
  data.append("width", String(floor.viewport.width));
  data.append("height", String(floor.viewport.height));
  data.append("level", String(floor.level));
  data.append("file", picture);

  return Axios.post("/floor", data);
};

export const editFloor = (
  uid: string,
  arg: { name?: string; level?: string; permissions?: any }
) => {
  return Axios.patch(`/floor/${uid}`, arg);
};

/** @deprecated use useRemoteSetUserSettings instead */
export const setUserSettings = (settings: AppSettings) => {
  return Axios.post("/user-settings", settings);
};

/** @deprecated use useRemoteGetAllCostCentersForCompany instead */
export const getAllCostcentersForCompanyId = (companyId: number) => {
  return Axios.get(`${getOrgBackend()}/erp/companies/${companyId}/costcenters`);
};

/** @deprecated use useRemoteUpdateCostCenter instead */
export const updateCostCenterProperties = (
  companyId: number,
  dto: {
    id: string;
    companyId: number;
    name: string;
    costCenterResponsibleMail?: string;
    active?: boolean;
    description?: string;
  }
) => {
  return Axios.post(`${getOrgBackend()}/erp/companies/${companyId}/costcenters/manually`, dto);
};

/** @deprecated use useRemoteCreateOrUpdateUser instead */
export const createOrUpdateUser = (dto: {
  companyId: number;
  userEmail: string;
  isActive: boolean;
  employeeResponsibleEmail: string;
  username: string;
  firstName: string;
  lastName: string;
  departmentId: string | null;
}) => {
  return Axios.post(`${getOrgBackend()}/create-update-users`, [dto]);
};

/** @deprecated use useRemoteDeleteUsers instead */
export const deleteUsers = (dto: { emails: string[]; alternativeTenantUserId?: string }) => {
  return Axios.post(`${getOrgBackend()}/delete-users`, dto);
};

/** @deprecated use useRemoteFetchAdmin instead */
export const getAdmin = (mode: UserRole) => {
  return Axios.get(`${getOrgBackend()}/admin/noLoc-admins?adminType=${mode}`);
};

/** use useRemoteFetchUserInfoForAllUsers instead */
export const getAllUsers = () => {
  return Axios.get(`${getOrgBackend()}/user-info/all-users`);
};

/** @deprecated use useRemoteFetchCostCenterUsers instead */
export const getCostcenterUsers = (id: string) => {
  return Axios.get(
    `${getOrgBackend()}/user-info/cost-center-units-users?costCenterUnitIds=${[id]}`
  );
};

/** @deprecated use useRemoteSetCostCenterUnitsUser instead */
export const setCostCenterUnitUser = (dto: { costCenterUnitId: string; emails: string[] }) => {
  return Axios.post(`${getOrgBackend()}/user-info/cost-center-units-users`, dto);
};

/** @deprecated use useRemoteSetAdmin instead */
export const setAdmin = (setMainAdminDto: {
  userIds: string[];
  adminType: UserRole;
  emails?: string[];
}) => {
  return Axios.post(`${getOrgBackend()}/admin/noLoc-admins`, setMainAdminDto);
};

export const setBookingTypeInterval = (
  dto: {
    bookingTypeId: number;
    tenantId: number;
    timeIntervall: number;
  }[]
) => {
  return Axios.post(`/booking-type/booking-type-interval`, dto);
};

export const getLocAdmins = (
  locationInventoryId: number,
  adminType: UserRole,
  repoClass: string | undefined
) => {
  return Axios.get(
    `/admin/loc-admins?locationInventoryId=${locationInventoryId}&adminType=${adminType}&repoClass=${repoClass}`
  );
};

export const getLocRequestAdmins = (locationInventoryId: number, adminType: UserRole) => {
  return Axios.get(
    `/admin/loc-admins?locationInventoryId=${locationInventoryId}&adminType=${adminType}`
  );
};

export const setLocAdmins = (setLocationAdminDto: {
  userIds: string[];
  emails?: string[];
  locationInventoryId: number;
  adminType: UserRole;
  repoClass: string | undefined;
}) => {
  return Axios.post("/admin/loc-admins", setLocationAdminDto);
};

export const getColleagues = () => {
  return Axios.get<Colleague[]>(`${getOrgBackend()}/connection/colleagues`);
};

export const getTeamBookingColleagues = () => {
  return Axios.get(`${getOrgBackend()}/connection/teamBookingColleagues`);
};

export const getInquiries = () => {
  return Axios.get(`${getOrgBackend()}/connection/inquiries`);
};

export const updateColleagues = (setConnectionDto: [Colleague]) => {
  const DTO = setConnectionDto[0];

  DTO.teamBookingPermission = Boolean(DTO.teamBookingPermission);
  DTO.teamBookingReqPermission = Boolean(DTO.teamBookingReqPermission);
  DTO.friendsColleague = Boolean(DTO.friendsColleague);
  DTO.friendsReqPermission = Boolean(DTO.friendsReqPermission);
  DTO.scheduleReqPermission = Boolean(DTO.scheduleReqPermission);
  DTO.schedulePermission = Boolean(DTO.schedulePermission);
  DTO.friendsPermission = Boolean(DTO.friendsPermission);
  DTO.teamBookingColleague = Boolean(DTO.teamBookingColleague);
  DTO.friendsReqColleague = Boolean(DTO.friendsReqColleague);
  DTO.scheduleReqColleague = Boolean(DTO.scheduleReqColleague);
  DTO.scheduleColleague = Boolean(DTO.scheduleColleague);
  DTO.teamBookingReqColleague = Boolean(DTO.teamBookingReqColleague);

  return Axios.post(`${getOrgBackend()}/connection`, { setConnectionDto: setConnectionDto });
};

export const setPermissions = (
  setConnectionDto: [
    {
      userId: string;
      friendsReq: boolean;
      friends: boolean;
      teamBooking: boolean;
      teamBookingReq: boolean;
      schedule: boolean;
      scheduleReq: boolean;
    }
  ]
) => {
  return Axios.post(`${getOrgBackend()}/connection/permissions`, {
    setConnectionDto: setConnectionDto
  });
};

export const getNonColleagues = () => {
  return Axios.get(`${getOrgBackend()}/connection/non-colleagues`);
};

export const bulkEquipmentExcelUpload = (excel: FileObject) => {
  if (excel.file) {
    const data = new FormData();
    data.append("file", excel.file);
    return Axios.post(`/equipment-upload`, data);
  }
};

export const bulkUserExcelUpload = (excel: FileObject) => {
  if (excel.file) {
    const data = new FormData();
    data.append("file", excel.file);
    return Axios.post(`${getOrgBackend()}/user-upload`, data);
  }
};

export const deleteCostCenter = (id: string, companyId: number) => {
  return Axios.delete(`${getOrgBackend()}/erp/companies/${companyId}/costcenters`, {
    params: { id }
  });
};

/** @deprecated use useRemoteFetchDeviceInventory instead */
export const getEquipmentInventory = () => {
  return Axios.get(`/equipment-inventory`);
};

export const createCompany = (newCompany: { name: string; refId: string }) => {
  return Axios.post(`${getOrgBackend()}/erp/companies`, {
    name: newCompany.name,
    refId: newCompany.refId
  });
};

/** @deprecated use useRemoteDeleteDevice instead */
export const deleteEquipment = (ids: number[]) => {
  return Axios.post(`/equipment-inventory/delete`, { ids });
};

export const createCategory = (newWorkPlaceCategory: ICategoryCrud, type: string) => {
  return Axios.post(`/${type}`, {
    name: newWorkPlaceCategory.name,
    description: newWorkPlaceCategory.description,
    costFactor: newWorkPlaceCategory.costFactor
  });
};

export const updateCategory = (newWorkPlaceCategory: ICategoryCrud, type: string) => {
  return Axios.patch(`/${type}`, {
    id: newWorkPlaceCategory.id,
    name: newWorkPlaceCategory.name,
    description: newWorkPlaceCategory.description,
    costFactor: newWorkPlaceCategory.costFactor
  });
};

export const deleteCategory = (id: number, type: string) => {
  return Axios.delete(`/${type}`, {
    params: { id: id }
  });
};

/** @deprecated can be replaced with the hook useRemoteFetchZoneCategory */
export const getZoneCategories = (): Promise<AxiosResponse<IZoneCategory[], any>> => {
  return Axios.get<IZoneCategory[]>("/zone-category");
};

export const createZoneCategory = (newZoneCategory: ZoneCategory) => {
  return Axios.post(`/zone-category`, {
    name: newZoneCategory.name,
    description: newZoneCategory.description,
    picturePath: newZoneCategory.picturePath,
    defaultRejected: newZoneCategory.defaultRejected,
    categories: newZoneCategory.categories
  });
};

export const updateZoneCategory = (newZoneCategory: ZoneCategory) => {
  return Axios.patch(`/zone-category`, {
    name: newZoneCategory.name,
    id: newZoneCategory.id,
    categories: newZoneCategory.categories,
    description: newZoneCategory.description,
    picturePath: newZoneCategory.picturePath,
    defaultRejected: newZoneCategory.defaultRejected
  });
};

export const deleteZoneCategory = (id: number) => {
  return Axios.delete(`/zone-category`, {
    params: { id: id }
  });
};

export const getScheduleColleagues = () => {
  return Axios.get(`${getOrgBackend()}/connection/scheduleColleagues`);
};

export const getPlaceSchedule = (bookingScheduleDto: BookingScheduleDto) => {
  return Axios.get<BookingScheduleInterface[]>(`/booking-schedule/place`, {
    params: bookingScheduleDto
  });
};

/** @deprecated use hook instead */
export const getAllBookingTypesOfTenant = (tenantId: number) => {
  return Axios.get(`/booking-type/${tenantId}`);
};

/** @deprecated use useRemoteFetchAvailabilityTimeOnTheFly instead */
export const availabilityTimeOnTheFly = (dto: { uid: string; bookingType: BookingType }) => {
  return Axios.get("/booking/availability-time-booking-on-the-fly", { params: dto });
};

export const getPlaceZoneSchedule = (bookingScheduleDto: BookingScheduleDto) => {
  return Axios.get<BookingScheduleInterface[]>(`/booking-schedule/placezone`, {
    params: bookingScheduleDto
  });
};

export const getUserZoneBookingCompanies = (filter: { userId: string }) => {
  return Axios.get(`/booking-schedule/user-zone-booking-companies`, {
    params: filter
  });
};

export const getCapacityAssistantValues = () => {
  return Axios.get(`/capacity-management-assistent-info/capacity-management-assistent-values`);
};

export const getCapacityAssistantInfo = (filter: {
  entityInventoryIds: number[];
  entityType: EntityType;
}) => {
  return Axios.get(`/capacity-management-assistent-info`, {
    params: filter
  });
};

export const createOrUpdateCapacityInfo = (infoDto: CapacityManagementAssistentInfoDto) => {
  return Axios.post(`/capacity-management-assistent-info`, infoDto);
};

export const getUserZoneBookingObjects = (filter: { userId: string; companyId: number }) => {
  return Axios.get(`/booking-schedule/user-zone-booking-objects`, {
    params: { filter }
  });
};

export const updateBookingTimes = (dto: UpdateBookingTimes) => {
  delete dto.event;
  return Axios.put(`/booking-schedule/update-booking-times`, dto);
};

export const checkout = (bookingId: number) => {
  return Axios.delete(`/checkin/${bookingId}`);
};

/** @deprecated use hook instead */
export const getLocationsWithoutMobileWorking = (
  isFinanceRequest: boolean,
  noMobileWorking: boolean,
  isBookingSettings?: boolean
) => {
  return Axios.get(`/location/companies-schedule`, {
    params: {
      noMobileWorking,
      isFinanceRequest,
      isBookingSettings
    }
  });
};

/** @deprecated can be replaced with the hook useRemoteFetchCategoryPerType */
export const getCategories = (type: string): Promise<AxiosResponse<ICategoryCrud[], any>> => {
  return Axios.get<ICategoryCrud[]>(`/${type}`);
};

export const newPlaceBooking = (placeBookingDto: PlaceBookingDto) => {
  return Axios.post(`/booking/place`, placeBookingDto);
};

/** @deprecated use useRemoteBookingOnTheFly instead */
export const newPlaceBookingOnTheFly = (dto: Booking) => {
  return Axios.post(`/workplace-booking/booking-on-the-fly`, dto);
};

export const getUserExistingZoneBooking = (filter: CheckZoneBookings) => {
  return Axios.post(`/booking/get-existing-zone-booking`, filter);
};

export const getUserAccessLocations = (perspective: any) => {
  return Axios.get(`/user-access`, {
    params: { perspective }
  });
};

/** @deprecated use useRemoteGetLocationGeoCodeByUid instead */
export const getLocationGeoCodeByWorkplaceUid = (filter: { uid: string }) => {
  return Axios.get(`/location/geo`, {
    params: filter
  });
};

export const getLocations = () => {
  return Axios.get(`/location`);
};

export const newPlaceZoneBooking = (placezoneBookingDto: PlaceZoneBookingDto) => {
  return Axios.post(`/booking/placezone`, placezoneBookingDto);
};

export const newNoPlaceZoneBooking = (conferenceBookingDto: ConferenceBookingDto) => {
  return Axios.post(`/booking/noplacezone`, conferenceBookingDto);
};

export const quickAutomatedPlaceBooking = (dto: Booking & { locationInventoryId: number }) => {
  return Axios.post(`/automated-booking/quick-auto-workplace-booking`, dto);
};

export const newConferenceZoneBookingActivityBased = (
  conferenceBookingDto: ConferenceBookingDto
) => {
  return Axios.post(`/automated-booking/activity-based-conference`, conferenceBookingDto);
};

export const getCompanyUnitsByUserId = (filter: {
  userId: string;
  companyId: number;
  zoneBookingSelectionDto?: { zoneInventoryId: number; start: string; end: string }[];
}) => {
  return Axios.post(`${getOrgBackend()}/tenant/get-user-company-units`, filter);
};

export const getUsersCompanies = (filter: { userIds: (string | undefined)[] }) => {
  return Axios.get(`/user-info/users-company`, {
    params: filter
  });
};

/** @deprecated use hook instead */
export const inactivateBookings = (inactivateBookingsDto: InactivateBookingsDto) => {
  return Axios.patch<InactivateBookingsResponse>(
    `/booking/inactivate-booking`,
    inactivateBookingsDto
  );
};

export const updateUserAccessLocations = (dto: any[]) => {
  return Axios.patch(`/user-access`, { permissions: dto });
};

export const rejectWorkplaceCategoryBookings = (
  dto: {
    userId: string;
    workplaceCategoryId: number;
    rejected: boolean;
  }[]
) => {
  return Axios.patch(`/booking-approval/place-category`, dto);
};

export const getZoneBookingReportTimeframe = (filter: getZoneBookingTimeframeDto) => {
  return Axios.get(`/report/zone-booking-report-timeframe`, {
    params: filter
  });
};

export const getCapacityManagerReportTimeframe = (filter: { data: number[] }) => {
  return Axios.get(`/report/capacity-manager-report-timeframe`, {
    params: filter
  });
};

export const getFinanceReportTimeframe = (filter: getCapacityManagerOrFinanceTimeframeDto) => {
  return Axios.get(`/report/finance-report-timeframe`, {
    params: filter
  });
};

export const getCapacityManagerReportEntities = (filter: getCapacityManagerEntitiesDto) => {
  return Axios.get(`/report/capacity-manager-report-entities`, {
    params: filter
  });
};

export const getFinanceReport = (filter: getFinanceReportDto) => {
  return Axios.get(`/report/finance-report`, {
    params: filter
  });
};

export const getCapacityManagerReport = (filter: getCapacityMangerOrZoneBookingReportDto) => {
  return Axios.get<CapacityPerformance>(`/report/capacity-report`, {
    params: filter
  });
};

export const getSupervisorReportTimeframe = (filter: string[]) => {
  return Axios.get(`/report/supervisor-report-timeframe`, {
    params: { users: filter }
  });
};

/** @deprecated replaced with hook */
export const getUserReport = (filter: { startDate: string; endDate: string }) => {
  return Axios.get(`/report/user-report`, {
    params: filter
  });
};

export const getLocationPricingParameters = (filter: { locationInvId: string | null }) => {
  return Axios.get(`/finance/location-pricing-parameters`, {
    params: filter
  });
};

export const getSupervisorEmployees = (filter: { supervisorId: string }) => {
  return Axios.get(`/user-info/supervisor-employees`, {
    params: filter
  });
};

export const calculateOfficeUsage = (calculationDto: any) => {
  return Axios.post(`/finance`, calculationDto);
};

export const fetchLocationAvailability = (
  args: GetBookingLocationParams & { locationInventoryId: number }
) => {
  return Axios.post("/availability/locations", args);
};

/** @deprecated replaced with hook */
export const setMetaData = (meta: FormData) => {
  return Axios.patch(`/meta`, meta, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

/** @deprecated replaced with hook */
export const getMetaData = () => {
  return Axios.get(`/meta`);
};

export const setAutomatedBookingConfiguration = (dto: WeekdaySelection) => {
  return Axios.patch(`/automated-booking/workplace-configuration`, dto);
};

export const getZoneBookingUserByZoneBookings = (filter: {
  userBookedFor: string;
  zoneBookingIds: (number | string)[];
}) => {
  return Axios.get(`/booking-schedule/zone-booking-user`, { params: filter });
};

export const setZoneBookingUserByZoneBookings = (dto: {
  userBookedFor: string;
  updatedZoneBookings: {
    zoneBookingId: string | number;
    zoneBookingUsers: UserOrEmailMapping[];
  }[];
}) => {
  return Axios.patch(`/booking-schedule/zone-booking-user`, dto);
};

export const getUserCapacityManagerLocations = () => {
  return Axios.get(`/user-info/user-capacity-manager-locations`, {
    params: { calcPerDay: true }
  });
};

export const getZoneBookingReportLocations = (dto: {
  userId: string;
  reportType: string;
  calculateWithoutCosts: boolean;
  companyId: number;
  zoneBookingObject: string;
  start: string;
  end: string;
}) => {
  return Axios.get(`/user-info/user-zone-booking-report-locations`, {
    params: dto
  });
};

export const setHourlyWorkplaceCost = (dto: {
  periodLength: number;
  budget: number;
  workingHoursPerMonth: number;
  vacancyFactor: number;
  incentiveFactor: number;
  id: number;
  start: string;
  defaultDeskCost: number;
}) => {
  return Axios.patch(`/finance`, { schedule: dto });
};

export const getAutomatedWorkplaceBookingConfiguration = () => {
  return Axios.get(`/automated-booking/workplace-configuration`);
};

export const getUserApprovalPlaceBookings = () => {
  return Axios.get<BookingApproval[]>(`/booking-approval/place`);
};

export const getUserApprovalPlacezoneBookings = () => {
  return Axios.get<BookingApproval[]>(`/booking-approval/placezone`);
};

export const getUserApprovalNoPlacezoneBookings = () => {
  return Axios.get<BookingApproval[]>(`/booking-approval/noplacezone`);
};

export const getUserWorkplaceCategoryBookings = () => {
  return Axios.get(`/booking-approval/place-category`);
};

export const getDeviceManagementReportInitialTimeframe = (dto: {
  initialTimeframeRepo: string;
  devManReportPerspectiveIds: number[];
  perspective: string;
}) => {
  return Axios.get(`/report/device-management-initial-timeframe`, { params: dto });
};

export const getWorkPreferenceReport = (dto: {
  userIds: string[];
  start: string;
  bookingType: string;
  end: string;
}) => {
  return Axios.get(`/report/work-preferences-report`, { params: dto });
};

export const getDeviceManagementReportFilterObjects = (dto: {
  deviceManagementFilterObjectRepo: string;
  deviceManagementFilterObjectPerspectiveType: string;
  deviceManagementReportType: string;
  entityType: string;
  startDate: string;
  endDate: string;
  perspectiveIds: number[];
}) => {
  return Axios.get(`/report/device-management-filter-objects`, { params: dto });
};

export const getDeviceManagementReport = (dto: {
  deviceManagementReportFilterObjectIds: number[];
  entityIds: number[];
  deviceManagementReportType: string;
  entityType: string;
  startDate: string;
  endDate: string;
  perspective: string;
  perspectiveRepo: string;
  counterPerspectiveRepo: string;
}) => {
  return Axios.get(`/report/device-management-report`, { params: dto });
};

/** @deprecated use hook instead */
export const getCompanies = () => {
  return Axios.get(`${getOrgBackend()}/tenant/companies`);
};

export const getCompanyUnits = (
  type: Stakeholder.ProjectUnit | Stakeholder.CostCenterUnit | Stakeholder.WorkOrderUnit
) => {
  switch (type) {
    case Stakeholder.ProjectUnit:
      return Axios.get(`${getOrgBackend()}/tenant/project-units`);

    case Stakeholder.CostCenterUnit:
      return Axios.get(`${getOrgBackend()}/tenant/costcenter-units`);

    case Stakeholder.WorkOrderUnit:
      return Axios.get(`${getOrgBackend()}/tenant/workorder-units`);
  }
};

export const getNoPlaceZoneBookingSchedule = (dto: {
  userIds: string[];
  startDate: string;
  endDate: string;
}) => {
  return Axios.get<BookingScheduleInterface[]>(`/booking-schedule/noplacezone`, {
    params: dto
  });
};

export const updateNoPlaceZoneBookingScheduleAttributes = (dto: UpdateConferenceAttributes) => {
  return Axios.put(`/booking-schedule/no-place-zone-booking-attributes`, dto);
};

export const updateNoPlaceZoneBookingAttachments = (
  noPlaceZoneBookingId: number,
  attachmentData: FormData
) => {
  return Axios({
    url: `/no-place-zone-attachments/${noPlaceZoneBookingId}`,
    method: "PUT",
    data: attachmentData
  });
};

export const deleteNoPlaceZoneBookingAttachment = (attachmentId: number) => {
  return Axios({
    url: `/no-place-zone-attachments/${attachmentId}`,
    method: "DELETE"
  });
};

export const setConferenceZoneBookingGuestByConferenceZoneBookings = (dto: {
  userBookedFor: string;
  updatedConferenceZoneBookings: [
    {
      conferenceZoneBookingId: number | string;
      conferenceZoneBookingGuests: UserOrEmailMapping[];
    }
  ];
}) => {
  return Axios.patch(`/booking-schedule/conference-zone-booking-guests`, dto);
};

export const getConferenceZoneBookingGuestByConferenceZoneBookings = (dto: {
  userBookedFor: string;
  conferenceZoneBookingIds: (number | string)[];
}) => {
  return Axios.get(`/booking-schedule/conference-zone-booking-guest`, { params: dto });
};

export const updateConferenceZoneBookingTimes = (dto: UpdateBookingTimes) => {
  delete dto.event;
  return Axios.put(`/booking-schedule/update-conference-zone-booking-times`, dto);
};

export const saveDisabledScheduleForLocation = (
  timeframe: DisabledSchedule,
  locationInventoryId: number
) => {
  return Axios({
    url: `${bookingBackend}/location/${locationInventoryId}/disabled`,
    method: "POST",
    data: timeframe
  });
};

export const deleteDisabledScheduleForLocation = (id: number, locationInventoryId: number) => {
  return Axios({
    url: `${bookingBackend}/location/${locationInventoryId}/disabled/${id}`,
    method: "DELETE"
  });
};

export const saveDisabledScheduleForFloor = (
  timeframe: DisabledSchedule,
  floorInventoryId: number
) => {
  return Axios({
    url: `${bookingBackend}/floor/${floorInventoryId}/disabled`,
    method: "POST",
    data: timeframe
  });
};

export const deleteDisabledScheduleForFloor = (id: number, floorInventoryId: number) => {
  return Axios({
    url: `${bookingBackend}/floor/${floorInventoryId}/disabled/${id}`,
    method: "DELETE"
  });
};

/** @deprecated use useRemoteFetchCheckinInfo instead */
export const getCheckinInfo = (uid: string) => {
  return Axios({
    url: `${bookingBackend}/checkin/${uid}/`,
    method: "GET"
  });
};

/** @deprecated use hook instead */
export const getUserHomeOfficeInfo = () => {
  return Axios({
    url: `${bookingBackend}/workplace-booking/users-mobile-working-booking-info`,
    method: "GET"
  });
};

/** @deprecated replace with hook */
export const getUsersAllowedHomeOfficeDays = (userIds: string[]) => {
  return Axios({
    url: `${bookingBackend}/workplace-booking/users-allowed-home-office-days`,
    method: "GET",
    params: { userIds: userIds }
  });
};

/** @deprecated replaced with hook */
export const setUsersAllowedHomeOfficeDays = (
  userHomeOfficeDaysDto: (Weekdays & { userId: string })[]
) => {
  return Axios.patch(
    `${bookingBackend}/workplace-booking/users-allowed-home-office-days`,
    userHomeOfficeDaysDto
  );
};

/** @deprecated replaced with hook useRemoteCheckinWorkplace */
export const checkinWorkplace = (bookingId: number, dto: CheckinDto) => {
  return Axios.post<CheckinResponseDto>(`${bookingBackend}/checkin/${bookingId}/`, dto);
};

/** can be replaced with hook useRemoteFetchFilteredPlaceCategory */
export const getFilteredPlaceCategories = (
  filter: FilterDto
): Promise<AxiosResponse<IPlaceCategory[], any>> => {
  return Axios.get<IPlaceCategory[]>("/place-category/filtered", {
    params: filter
  });
};

export const getFilteredZoneCategories = (filter: FilterDto) => {
  return Axios.get("/zone-category/filtered", {
    params: filter
  });
};

export const getFilteredEquipmentCategories = (filter: FilterDto) => {
  return Axios.get("/equipment-category/filtered", {
    params: filter
  });
};

export const getFilteredEquipmentInventories = (filter: FilterDto) => {
  return Axios.get("/equipment-inventory/filtered", {
    params: filter
  });
};

export const patchPlaceEquipments = (dto: { equipments: number[]; schedule: number }) => {
  return Axios.patch(`${bookingBackend}/place-equipments`, dto);
};

export const getManyPlaceEquipments = (placeScheduleIds: number[]) => {
  return Axios.get<Record<number, PlaceScheduleEquipment[]>>(
    `${bookingBackend}/place-equipments/many`,
    {
      params: { ids: placeScheduleIds }
    }
  );
};

export const getLocationFloors = (locationInventoryId: number) => {
  return Axios.get<FloorInventory[]>(`${bookingBackend}/floor/${locationInventoryId}`);
};

export const getOccupationReport = (dto: {
  bookingInventoryIds: (number | undefined)[];
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  bookingType: BookingType;
  timezone: string;
}) => {
  return Axios.get<OccupationReport>(`${bookingBackend}/report/occupation-report`, {
    params: dto
  });
};

export const getOccupationExcel = (dto: {
  startDate: string;
  endDate: string;
  entityIds: number[];
  entityType: string;
}) => {
  return Axios.get(`${bookingBackend}/excel/bookings`, {
    params: dto
  });
};

export const getFloorPlan = (
  id: number,
  dto?: { start?: string; end?: string; earliestTimeframeOnly?: boolean }
) => {
  return Axios.get<FloorPayload>(`/plan/${id}`, {
    params: dto
  });
};

export const getFloorAvailability = (args: GetBookingFloorParams) => {
  return Axios.post("/booking/availability/floors", args);
};

export const getFloorPlanByWorkplaceInventoryId = (
  id: number,
  dto?: { start?: string; end?: string; earliestTimeframeOnly?: boolean }
) => {
  return Axios.get<IFloorPayload>(`${bookingBackend}/plan/place/${id}`, {
    params: dto
  });
};

export const getFloorPlanByZoneInventoryId = (
  id: number,
  dto?: { start?: string; end?: string; earliestTimeframeOnly?: boolean }
) => {
  return Axios.get<IFloorPayload>(`${bookingBackend}/plan/zone/${id}`, {
    params: dto
  });
};

export function getFloorPlanSchema(floorInventoryId: number, start: string, end: string) {
  return Axios.get(`/plan/${floorInventoryId}`, {
    params: {
      earliestTimeframeOnly: false,
      start,
      end
    }
  });
}
