import { useState } from "react";
import {
  Alert,
  Button,
  Card,
  Divider,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch } from "../../../../../../app/helpers";
import { setInputs } from "../../../../../../features/Booking-Form/slices/booking.slice";
import { IPlaceSchedule } from "../../../../Domain/Types/FloorPlan/PlaceSchedule";
import { IZoneSchedule } from "../../../../Domain/Types/FloorPlan/ZoneSchedule";
import { PlaceBookingDetailDialog } from "../PlaceSelectionPrompt/PlaceBookingDetailDialog";
import { BookingInputs } from "../../../../Domain/Types/BookingInputs.type";
import { useRemoteFetchBookingStatusInformation } from "../../../../../../hooks/Remote/Booking/useRemoteFetchBookingStatusInformation";
import { checkWeekdays, generateBtnText } from "./SelectionPrompt.functions";
import { ScheduleContentList } from "../../../../../Schedule/ScheduleCalendar/ScheduleSelectionDialog/ScheduleContentList.component";
import { ShowMoreContentIcon } from "./partial/ShowMoreContainerIcon.component";
import { SelectionOccupiedInfo } from "./partial/SelectionPromptOccupiedInfo.component";
import { SelectionPromptHeader } from "./partial/SelectionPromptHeader.component";

interface Props {
  selected: { place: IPlaceSchedule | undefined; zone: IZoneSchedule | undefined };
  /** true when the selected place is available, false when it is occupied/disabled/not permitted */
  selectableByVariant: boolean;
  bookingInputs: BookingInputs;
  userIndex: number;
  userId: string;
  onConfirm: () => void;
  onClose: (e: any) => void;
  setIsDateOpen: (o: boolean) => void;
}

/**
 * new selection prompt in the booking process
 * @version 0.1.1
 */
export function SelectionPrompt2({
  selected,
  selectableByVariant = true,
  bookingInputs,
  userIndex,
  userId,
  onConfirm,
  onClose,
  setIsDateOpen
}: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const theme = useTheme();
  // when is mobile or tablet, display selection prompt as dialog in the center
  const isBrowser = useMediaQuery(theme.breakpoints.up(1200));

  const selectedEntry = selected.place || selected.zone;

  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [showMoreDescription, setShowMoreDescription] = useState(false);
  const [showMoreEquipment, setShowMoreEquipment] = useState(false);
  const [showMoreEquipCategory, setShowMoreEquipCategory] = useState(false);
  const [showMoreBookProp, setShowMoreBookProp] = useState(false);
  const [showMoreCategory, setShowMoreCategory] = useState(false);

  const { data: statusInformationData } = useRemoteFetchBookingStatusInformation(
    {
      bookingInventoryIds: [selectedEntry?.inventoryId || selectedEntry?.inventory?.id],
      startDate: bookingInputs.bookingFrom,
      endDate: bookingInputs.bookingTo,
      startTime: bookingInputs.bookingStart,
      endTime: bookingInputs.bookingEnd,
      bookingType: bookingInputs.bookingType,
      timezone: bookingInputs.timezone,
      weekdays: checkWeekdays(bookingInputs.weekdays)
    },
    !!selected && !selectableByVariant // make a request only when the selected place is not available
  );

  const occupiedStatus = statusInformationData?.status.flatMap(sta => sta.occupiedStatus);

  const contentChildItemStyle = (showMore: boolean) => {
    return { flexWrap: "nowrap", overflowY: "auto", maxHeight: showMore ? "180px" : "unset" };
  };

  return (
    <>
      <Card
        sx={{
          p: 2,
          minWidth: isBrowser ? 400 : "fit-content",
          maxWidth: isBrowser ? 400 : "unset"
        }}
      >
        {selectedEntry && (
          <Stack direction={"column"} spacing={2} data-testid="selection-prompt">
            {/* selection prompt header */}
            <SelectionPromptHeader
              isBrowser={isBrowser}
              selectedEntry={selectedEntry}
              setIsDateOpen={setIsDateOpen}
              onClose={onClose}
            />

            {/* if occupied booking is existed */}
            {!selectableByVariant && (
              <SelectionOccupiedInfo
                statusInformationData={statusInformationData}
                occupiedStatus={occupiedStatus}
                setIsDetailModalOpen={setIsDetailModalOpen}
              />
            )}

            {/* description */}
            <ScheduleContentList
              contentIcon={
                <ShowMoreContentIcon
                  type={"Description"}
                  showMoreContent={showMoreDescription}
                  setShowMoreContent={setShowMoreDescription}
                />
              }
              contentTitle={t("Description")}
              contents={[]}
              contentChildren={
                <Grid container rowGap={1} flexDirection={"column"}>
                  <Grid
                    item
                    sx={{
                      whiteSpace: "pre-line",
                      overflowY: showMoreDescription ? "scroll" : "hidden",
                      maxHeight: showMoreDescription ? "150px" : "30px"
                    }}
                  >
                    {selectedEntry.description}
                  </Grid>
                </Grid>
              }
            />

            {/* equipment list */}
            {selectedEntry.equipments && selectedEntry.equipments.length > 0 && (
              <ScheduleContentList
                contentIcon={
                  <ShowMoreContentIcon
                    type={"Equipment"}
                    showMoreContent={showMoreEquipment}
                    setShowMoreContent={setShowMoreEquipment}
                  />
                }
                contentTitle={t("Equipment")}
                contents={[]}
                contentChildren={
                  <Grid
                    container
                    rowGap={1}
                    flexDirection={"column"}
                    sx={{ ...contentChildItemStyle(showMoreEquipment) }}
                  >
                    {selectedEntry.equipments.map((equip, index) => {
                      const length = selectedEntry.equipments.length;
                      return (
                        <div key={equip.id}>
                          <Grid item>{equip.name}</Grid>
                          {showMoreEquipment && (
                            <>
                              <Grid item pl={2}>
                                {equip.description}
                              </Grid>
                              {index !== length - 1 && <Divider />}
                            </>
                          )}
                        </div>
                      );
                    })}
                  </Grid>
                }
              />
            )}

            {/* equipment category list */}
            {selectedEntry.equipmentCategories && selectedEntry.equipmentCategories.length > 0 && (
              <ScheduleContentList
                contentIcon={
                  <ShowMoreContentIcon
                    type={"Equipment Category"}
                    showMoreContent={showMoreEquipCategory}
                    setShowMoreContent={setShowMoreEquipCategory}
                  />
                }
                contentTitle={t("Equipment Category")}
                contents={[]}
                contentChildren={
                  <Grid
                    container
                    rowGap={1}
                    flexDirection={"column"}
                    sx={{ ...contentChildItemStyle(showMoreEquipCategory) }}
                  >
                    {selectedEntry.equipmentCategories.map((equipCate, index) => {
                      const length = selectedEntry.equipmentCategories?.length ?? 0;

                      return (
                        <div key={equipCate.id}>
                          <Grid item container>
                            <Grid item xs={9}>
                              {equipCate.name}
                            </Grid>
                            {showMoreEquipCategory && (
                              <Grid item xs={2} display={"flex"} sx={{ placeContent: "end" }}>
                                {t("Qty")}
                                <Typography sx={{ pl: 1 }}>{equipCate.quantity}</Typography>
                              </Grid>
                            )}
                          </Grid>
                          {showMoreEquipCategory && (
                            <>
                              <Grid item pl={2}>
                                {equipCate.description}
                              </Grid>
                              {index !== length - 1 && <Divider />}
                            </>
                          )}
                        </div>
                      );
                    })}
                  </Grid>
                }
              />
            )}

            {/* properties list */}
            {selectedEntry.bookingProperties && selectedEntry.bookingProperties?.length > 0 && (
              <ScheduleContentList
                contentIcon={
                  <ShowMoreContentIcon
                    type={"BookingProperty"}
                    showMoreContent={showMoreBookProp}
                    setShowMoreContent={setShowMoreBookProp}
                  />
                }
                contentTitle={t("Booking Property")}
                contents={[]}
                contentChildren={
                  <Grid
                    container
                    rowGap={1}
                    flexDirection={"column"}
                    sx={{ ...contentChildItemStyle(showMoreBookProp) }}
                  >
                    {selectedEntry.bookingProperties.map((prop, index) => {
                      const length = selectedEntry.bookingProperties?.length ?? 0;
                      return (
                        <div key={prop.id}>
                          <Grid item>{prop.name}</Grid>
                          {showMoreBookProp && (
                            <>
                              <Grid item pl={2}>
                                {prop.description}
                              </Grid>
                              {index !== length - 1 && <Divider />}
                            </>
                          )}
                        </div>
                      );
                    })}
                  </Grid>
                }
              />
            )}

            {/* category */}
            {selectedEntry.category && (
              <ScheduleContentList
                contentIcon={
                  <ShowMoreContentIcon
                    type={"Category"}
                    showMoreContent={showMoreCategory}
                    setShowMoreContent={setShowMoreCategory}
                  />
                }
                contentTitle={t("Category")}
                contents={[]}
                contentChildren={
                  <Grid container rowGap={1} flexDirection={"column"}>
                    <Grid item>{selectedEntry.category?.name}</Grid>
                    {showMoreCategory && (
                      <Grid item pl={2}>
                        {selectedEntry.category?.description}
                      </Grid>
                    )}
                  </Grid>
                }
              />
            )}

            {/* confirm button */}
            <Button
              disabled={!selectableByVariant}
              variant={"contained"}
              data-testid={"select-pl-btn"}
              onClick={() => {
                onConfirm();
                dispatch(setInputs({ bookingDays: statusInformationData?.bookingDays }));
              }}
            >
              {generateBtnText(
                selectableByVariant,
                bookingInputs,
                selectedEntry.approvalRequired,
                userIndex,
                userId,
                statusInformationData,
                t
              )}
            </Button>
          </Stack>
        )}

        {!selectedEntry && <Alert variant={"filled"} severity={"error"} title={"Oops"} />}

        <PlaceBookingDetailDialog
          isDetailModalOpen={isDetailModalOpen}
          setIsDetailModalOpen={setIsDetailModalOpen}
          timezone={bookingInputs.timezone}
          occupiedStatus={occupiedStatus}
        />
      </Card>
    </>
  );
}
