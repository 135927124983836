import React, { Dispatch, SetStateAction, useState } from "react";
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { Cancel, Check, Edit } from "@mui/icons-material";
import { Datepicker, MbscCalendarEvent } from "@mobiscroll/react";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import { useSelector } from "../../app/helpers";
import { RootState } from "../../app/rootReducer";
import useTimeFormat from "../../hooks/useTimeFormat/useTimeFormat";
import { updateTimes } from "./schedule.functions";
import { BookingScheduleInterface } from "../../features/Booking-Form/typings/booking.types";
import { validTimeRange } from "../BookingForm/date-time.functions";
import useTimezone from "../../hooks/useTimezone/useTimezone";

type InlineTimeEdit = {
  isEditing: boolean;
  valueBefore: {
    start: string;
    end: string;
  };
  value: {
    start: string;
    end: string;
  };
};

type P = {
  schedule: BookingScheduleInterface;
  calendarData: MbscCalendarEvent[];
  setCalendarData?: Dispatch<SetStateAction<MbscCalendarEvent[]>>;
  refetchAllSchedule?: () => void;
};

export const ScheduleEventTime: React.FC<P> = ({
  schedule,
  calendarData,
  setCalendarData,
  refetchAllSchedule
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const {
    userInformation: { sub: userInformationSub },
    settings: { timezone: timezoneState }
  } = useSelector((state: RootState) => state.login);

  const deviceTimezone = useTimezone();

  // if the schedule's timezone isn't the users default, render users timezone
  const timezone = timezoneState !== schedule.timezone ? deviceTimezone : schedule.timezone;

  const { timeFormat, dateFormat } = useTimeFormat();
  const formattedStartTime = DateTime.fromISO(schedule.startDate)
    .setZone(timezone)
    .toFormat(timeFormat);
  const formattedEndTime = DateTime.fromISO(schedule.endDate)
    .setZone(timezone)
    .toFormat(timeFormat);

  const [editTime, setEditTime] = useState<InlineTimeEdit>({
    isEditing: false,
    valueBefore: {
      start: formattedStartTime || "",
      end: formattedEndTime || ""
    },
    value: {
      start: formattedStartTime || "",
      end: formattedEndTime || ""
    }
  });

  // compare schedule end time and now, then allow to edit the schedule time or not
  const isEndTimeInTheFuture =
    DateTime.fromISO(schedule.endDate).setZone(timezone).diffNow("minutes").minutes > 0;

  // compare schedule start time and now, then not allow to edit the start time already in the past. start < now < end
  const onlyEditEndTime = DateTime.fromISO(schedule.startDate) <= DateTime.now();

  const confirmChanges = async () => {
    onScheduleTimeUpdate({
      newStart: editTime.value.start,
      newEnd: editTime.value.end,
      scheduleId: schedule.id
    });
    setEditTime(e => ({ ...e, isEditing: false }));
  };

  const cancelChanges = () => {
    setEditTime(e => ({
      ...e,
      value: { start: editTime.valueBefore.start, end: editTime.valueBefore.end },
      isEditing: false
    }));
  };

  async function onScheduleTimeUpdate({
    newStart,
    newEnd,
    scheduleId
  }: {
    newStart: string;
    newEnd: string;
    scheduleId: number;
  }) {
    const newStartTime = DateTime.fromISO(newStart);
    const newEndTime = DateTime.fromISO(newEnd);
    const scheduleDate = DateTime.fromISO(schedule.start || schedule.startDate);

    const newStartIso =
      scheduleDate.set({ hour: newStartTime.hour, minute: newStartTime.minute }).toISO() ||
      DateTime.now().toISO();
    const newEndIso =
      scheduleDate.set({ hour: newEndTime.hour, minute: newEndTime.minute }).toISO() ||
      DateTime.now().toISO();

    await updateTimes(
      {
        bookingId: scheduleId,
        newStartTime: newStartIso,
        newEndTime: newEndIso,
        timezone
      },
      userInformationSub,
      schedule,
      calendarData,
      { enqueueSnackbar, setCalendarData, refetchAllSchedule, cancelChanges }
    );
  }

  return (
    <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
      <Box>
        <Grid container data-testid="schedule-event-time-par" display={"grid"}>
          <Box>
            <Typography data-testid="schedule-item-time">
              {DateTime.fromISO(schedule.startDate).toFormat(dateFormat)}
            </Typography>
            <Grid item display={"flex"}>
              {!editTime.isEditing && (
                <Typography data-testid="schedule-item-time" sx={{ mt: 0.2 }}>
                  {editTime.value.start} - {editTime.value.end}
                </Typography>
              )}
              {/* compare schedule end time and now, then allow to edit the schedule time or not */}
              {!editTime.isEditing &&
                isEndTimeInTheFuture &&
                schedule.userBookedFor === userInformationSub && (
                  <IconButton
                    onClick={e => {
                      e.stopPropagation();
                      setEditTime(e => ({ ...e, isEditing: true }));
                    }}
                    data-testid="edit-schedule-item-time"
                    sx={{ margin: "0 8px", padding: 0 }}
                    size="large"
                  >
                    <Edit fontSize="small" />
                  </IconButton>
                )}
            </Grid>
          </Box>
          <Grid
            item
            sx={{ display: "flex", alignItems: "flex-end", mb: "3px" }}
            data-testid="schedule-item-time-change-buttons"
          >
            {/* editing the booking schedule time */}
            {editTime.isEditing && (
              <Grid
                item
                xs={12}
                sx={{ minWidth: isMobile ? "14rem" : "fit-content" }}
                data-testid="schedule-item-time-change-date-picker"
              >
                <FormControl
                  fullWidth
                  data-testid="timepicker-control"
                  sx={{ margin: "-25px -0px -20px -18px" }}
                >
                  {/* edit both start and end time */}
                  {!onlyEditEndTime && (
                    <Datepicker
                      select={"range"}
                      data-testid={"timePicker"}
                      controls={["time"]}
                      timeFormat={timeFormat}
                      onChange={picker => {
                        if (picker.value && Array.isArray(picker.value)) {
                          const newStart = DateTime.fromJSDate(picker.value[0] as Date).toFormat(
                            "HH:mm"
                          );
                          const newEnd = DateTime.fromJSDate(picker.value[1] as Date).toFormat(
                            "HH:mm"
                          );

                          setEditTime(e => ({ ...e, value: { start: newStart, end: newEnd } }));
                        }
                      }}
                      value={[editTime.value.start, editTime.value.end]}
                      stepMinute={15}
                      valid={[
                        {
                          start: validTimeRange(schedule.startDate),
                          end: "23:59"
                        }
                      ]}
                    />
                  )}

                  {/* if start time is in the past than the now_time, can only edit the end time */}
                  {onlyEditEndTime && (
                    <Grid
                      container
                      display={"flex"}
                      flexWrap={"nowrap"}
                      sx={{ width: isMobile ? "13rem" : "17rem" }}
                    >
                      <Datepicker
                        disabled
                        data-testid={"start-timePicker"}
                        controls={["time"]}
                        timeFormat={timeFormat}
                        value={editTime.value.start}
                      />
                      <Datepicker
                        data-testid={"end-timePicker"}
                        controls={["time"]}
                        timeFormat={timeFormat}
                        onChange={picker => {
                          if (picker.value) {
                            const origStart = DateTime.fromISO(schedule.startDate)
                              .setZone(timezone)
                              .toFormat("HH:mm");
                            const newEnd = DateTime.fromJSDate(picker.value as Date).toFormat(
                              "HH:mm"
                            );

                            setEditTime(e => ({
                              ...e,
                              value: { start: origStart, end: newEnd }
                            }));
                          }
                        }}
                        value={editTime.value.end}
                        stepMinute={15}
                        valid={[
                          {
                            start: validTimeRange(schedule.startDate),
                            end: "23:59"
                          }
                        ]}
                      />
                    </Grid>
                  )}
                </FormControl>
              </Grid>
            )}
            {editTime.isEditing && (
              <Grid
                container
                sx={{ direction: "row", alignItems: "center", flexWrap: "nowrap", ml: "-25px" }}
              >
                <Grid item>
                  <IconButton
                    onClick={confirmChanges}
                    data-testid="save-editing-schedule-item-time"
                    size="small"
                  >
                    <Check fontSize="small" />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton size="small" data-testid="btn-cancel" onClick={cancelChanges}>
                    <Cancel fontSize="small" />
                  </IconButton>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>

        <Typography sx={{ mt: 0.1 }}>{timezone}</Typography>
      </Box>
    </Grid>
  );
};
